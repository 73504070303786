import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";

// ==== API Details ====
// https://stage.api.sloovi.com/feedback?sort=desc&page=1&filter_type=archive
// company_id and token everytime required
// can be found in axiosInstance
// =====================

export const getArchivesAsync = createAsyncThunk(
    "archives/getArchivesAsync",
    async (payload) => {
        // console.log(payload);
        const res = await axiosInstance.getArchive(payload.params);

        // console.log(res);
        if (res.data.code === 200) {
            return {
                archives: res.data.results.data,
                hasMore: res.data.results.has_more,
            };
        } else {
            throw new Error("Server Error!");
        }
    }
);

// Delete a Single Feedback
export const deleteFeedbackAsync = createAsyncThunk(
    "archives/deleteFeedbackAsync",
    async (id) => {
        console.log(id);
        await axiosInstance.deleteFeedback(id);

        return id;
    }
);

// Mark a feedback as Archived
export const markArchiveAsync = createAsyncThunk(
    "archives/markArchiveAsync",
    async (id) => {
        await axiosInstance.markArchive(id);

        return id;
    }
);

export const archiveSlice = createSlice({
    name: "archives",
    initialState: {
        loading: false,
        hasErrors: false,
        archives: [],
        hasMore: false,
    },
    reducers: {
        emptyArchive: (state, action) => {
            state.archives = [];
        },
    },
    extraReducers: {
        [getArchivesAsync.pending]: (state, action) => {
            state.loading = true;
        },
        [getArchivesAsync.fulfilled]: (state, action) => {
            state.archives = [...state.archives, ...action.payload.archives];
            state.hasMore = action.payload.hasMore;
            state.loading = false;
            state.hasErrors = false;
        },
        [getArchivesAsync.rejected]: (state, action) => {
            state.loading = false;
            state.hasErrors = true;
        },
        [deleteFeedbackAsync.fulfilled]: (state, action) => {
            state.archives = state.archives.filter(
                (item) => item.id !== action.payload
            );
        },
        [markArchiveAsync.fulfilled]: (state, action) => {
            state.archives = state.archives.filter(
                (item) => item.id !== action.payload
            );
        },
    },
});

export const { emptyArchive } = archiveSlice.actions;

export default archiveSlice.reducer;
