import Cookies from "universal-cookie";

const cookies = new Cookies();

// options - for getting the cookies data if it already exists
const options = {};
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    options.path = "/";
    options.domain = "localhost";
} else {
    options.path = "/";
    options.domain = "sloovi.com";
}

// ======= Token =======
export function setToken(userToken) {
    cookies.set("access_token", userToken, options);
}
export function getToken() {
    return cookies.get("access_token");
}
export function removeToken() {
    cookies.remove("access_token", options);
}

// ======= Comapny Id =======
export function setCompanyId(id) {
    cookies.set("__org_id", id, options);
}
export function getCompanyId() {
    return cookies.get("__org_id");
}
export function removeCompanyId() {
    cookies.remove("__org_id", options);
}
