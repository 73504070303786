import React from 'react'

const Error = () => {
    return (
        <div className="Box-root Padding-vertical--16">
            <div className="Text-align--center Text-color--invalid Padding-all--20 Card--radius--all">
                <p className="fa fa-exclamation-circle Text-fontSize--32 Margin-bottom--12"></p>
                <p>Oops! Something went wrong.</p>
            </div>
        </div>
    )
}

export default Error
