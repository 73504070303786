import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale-subtle.css";

const Filters = ({ setRole }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const filterHandler = (role) => {
        setRole(role);
        setShowDropdown(false);
    };

    return (
        <>
            <div
                className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8 dropdown"
                style={{ pointerEvents: "auto" }}
            >
                <Tippy
                    visible={showDropdown}
                    onClickOutside={() => setShowDropdown(false)}
                    animation="scale-subtle"
                    theme="light-border"
                    placement="bottom-end"
                    interactive={true}
                    content={
                        <ul className="submenu">
                            <li
                                className="reqtype"
                                onClick={() => filterHandler(0)}
                            >
                                All roles
                            </li>
                            <li
                                className="reqtype"
                                onClick={() => filterHandler(1)}
                            >
                                Administrator
                            </li>
                            <li
                                className="reqtype"
                                onClick={() => filterHandler(2)}
                            >
                                Restricted User
                            </li>
                        </ul>
                    }
                >
                    <button
                        className="wt-but btn"
                        onClick={() => setShowDropdown(true)}
                    >
                        <div className="Box-root Padding-right--8">
                            <div
                                aria-hidden="true"
                                className="SVGInline SVGInline--cleaned SVG Icon Icon--filter Button-icon Icon-color Icon-color--gray600 Box-root Flex-flex"
                                style={{
                                    transform: "translateY(1.1px)",
                                }}
                            >
                                <svg
                                    aria-hidden="true"
                                    className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--filter-svg Button-icon-svg Icon-color-svg Icon-color--gray600-svg"
                                    height={12}
                                    width={12}
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.994.004c.555 0 1.006.448 1.006 1a.997.997 0 0 1-.212.614l-5.782 7.39L9 13.726a1 1 0 0 1-.293.708L7.171 15.97A.1.1 0 0 1 7 15.9V9.008l-5.788-7.39A.996.996 0 0 1 1.389.214a1.01 1.01 0 0 1 .617-.21z"
                                        fillRule="evenodd"
                                    />
                                </svg>
                            </div>
                        </div>
                        Filters
                    </button>
                </Tippy>
            </div>
        </>
    );
};

export default Filters;
