import React, { useState, useEffect, useRef } from "react";
import { getToken } from "../../utils/tokenUtilities";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { RiFeedbackFill } from "react-icons/ri";

const Accept = () => {
    const history = useHistory();

    useEffect(() => {
        if (getToken()) {
            history.replace("/feedback/all");
        }
    }, [history]);

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");

    // refs for input form
    const f_nameRef = useRef();
    const l_nameRef = useRef();
    const passRef = useRef();
    const phoneRef = useRef();

    // check if /accept route has query params
    const query = new URLSearchParams(useLocation().search);
    const email = query.get("email");
    const u_id = query.get("u_id");
    const project_id = query.get("project_id");

    const submitHandler = async (e) => {
        e.preventDefault();
        const data = {
            first: f_nameRef.current.value,
            last: l_nameRef.current.value,
            email,
            password: passRef.current.value,
            phone: phoneRef.current.value,
            u_id,
            client_id: project_id,
        };
        console.log(data);

        setIsLoading(true);
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/accept?email=${email}&u_id=${u_id}&project_id=${project_id}&product=feedback`,
            data
        );
        setIsLoading(false);
        if (res.data.code === 302) {
            // send the query params to login route for auto login
            window.location.replace(res.data.results.url);
        } else {
            setMessage(res.data.message);
            f_nameRef.current.value = "";
            l_nameRef.current.value = "";
            passRef.current.value = "";
            phoneRef.current.value = "";
        }
    };

    return (
        <div className="login-wrapper">
            <p className="login-icon">
                <RiFeedbackFill />
            </p>
            <h4>
                <b>Accept the Invitation</b>
            </h4>
            {message && <div className="alert alert-danger">{message}</div>}
            <form className="login-form" onSubmit={submitHandler}>
                <div className="Margin-bottom--16">
                    <label htmlFor="fname">
                        <b>First Name</b>
                    </label>
                    <input
                        type="text"
                        id="fname"
                        className="form-control"
                        placeholder="Enter your first name"
                        ref={f_nameRef}
                        required
                    />
                </div>
                <div className="Margin-bottom--16">
                    <label htmlFor="lname">
                        <b>Last Name</b>
                    </label>
                    <input
                        type="text"
                        id="lname"
                        className="form-control"
                        placeholder="Enter your last name"
                        ref={l_nameRef}
                        required
                    />
                </div>
                <div className="Margin-bottom--16">
                    <label htmlFor="password">
                        <b>Password</b>
                    </label>
                    <input
                        type="password"
                        id="password"
                        className="form-control"
                        placeholder="*******"
                        ref={passRef}
                        required
                    />
                </div>
                <div className="Margin-bottom--16">
                    <label htmlFor="phoneNo">
                        <b>Phone Number</b>
                    </label>
                    <input
                        type="tel"
                        id="phoneNo"
                        className="form-control"
                        placeholder="Enter your phone number"
                        ref={phoneRef}
                        required
                    />
                </div>

                <div className="Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween Padding-top--8">
                    <button type="submit" style={{ width: "100%" }}>
                        {isLoading ? (
                            <span>
                                <i className="fa fa-spinner fa-pulse"></i>
                                <span className="sr-only">Loading...</span>
                            </span>
                        ) : (
                            "Sign Up"
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Accept;
