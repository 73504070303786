import React from "react";
import TableList from "./TableList";

const Table = ({ items, deleteFeedbackAsync, markArchiveAsync }) => {
    return (
        <>
            {/* -------- TABLE HEADER -------- */}
            <table className="table mb-0 wt-100 sm-ht fulltable" id="fb-main">
                <thead className="stik">
                    <tr>
                        <th
                            scope="col"
                            className="border-0 tb-title pl5"
                            style={{ width: "40%" }}
                        >
                            FEEDBACK TITLE
                        </th>
                        <th
                            scope="col"
                            className="border-0 tb-title pl5"
                            style={{ width: "30%" }}
                        >
                            USER
                        </th>
                        <th
                            scope="col"
                            className="border-0 tb-title pl5"
                            style={{ width: "12%" }}
                        >
                            TYPES
                        </th>
                        <th
                            scope="col"
                            className="border-0 tb-title pl5"
                            style={{ width: "12%" }}
                        >
                            DATE
                        </th>
                        <th
                            scope="col"
                            className="border-0"
                            style={{ width: "4%" }}
                        />
                    </tr>
                </thead>
            </table>

            {/* -------- TABLE CONTENT -------- */}
            <div id="allfeedback">
                {/*Start Accordion Menu */}
                <TableList
                    items={items}
                    deleteFeedbackAsync={deleteFeedbackAsync}
                    markArchiveAsync={markArchiveAsync}
                />
            </div>
            {/* #allfeedback */}
        </>
    );
};

export default Table;
