import React, { useState } from "react";
import { removeToken, removeCompanyId } from "../../utils/tokenUtilities";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { removeCredentials } from "../../redux/authSlice";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale-subtle.css";
import Widget from "./Widget";
import { CgMenuGridO } from "react-icons/cg";
import { IoMdSettings } from "react-icons/io";
import { useEffect } from "react";
import { fetchSwictIconContent } from "../../redux/switchIconsSlice";
import Skeleton from "../Skeleton/Skeleton";

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory((state) => state.switchProductIcons);
  const { isLoading, products } = useSelector(
    (state) => state.switchProductIcons
  );

  const [settingsDropdown, setSettingsDropdown] = useState(false);
  const [openAppIcon, setOpenAppIcon] = useState(false);

  const logoutHandler = () => {
    console.log("logging out");
    removeToken();
    removeCompanyId();
    dispatch(removeCredentials());
    history.replace("/login");
  };

  useEffect(() => {
    dispatch(fetchSwictIconContent());
  }, [dispatch]);

  console.log(products);

  return (
    <>
      {/* Top Nav area */}
      <div className="atd-World-topContent">
        <div className="atd-TopNav Box-root Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween">
          <div className="atd-Spotlight-inputContainer">
            <div className="search-group">
              <svg
                className="search-group__icon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#777777"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              <input
                type="text"
                className="search-group__input"
                placeholder="Search..."
              />
              <span className="search-group__shortcut">/</span>
            </div>
          </div>
          {/*Search Bar Wrapper*/}
          <div className="Box-root">
            <div
              className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
              style={{ marginLeft: "-16px", marginTop: "-16px" }}
            >
              <Widget />

              <div className="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--16">
                <div className="Box-root Flex-flex">
                  <div className="Box-root Flex-flex">
                    <div
                      aria-controls="menu2"
                      aria-haspopup="menu"
                      id="menu2-button"
                      tabIndex={-1}
                      style={{ outline: "none" }}
                    >
                      <div className="atd-NotificationsX-button">
                        <div
                          className="PressableCore PressableCore--radius--all PressableCore--width PressableCore--width--auto Box-root Flex-inlineFlex"
                          style={{
                            boxShadow:
                              "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px",
                          }}
                        >
                          <div className="PressableCore-base Box-root">
                            <Tippy
                              visible={settingsDropdown}
                              onClickOutside={() => setSettingsDropdown(false)}
                              offset={[0, 10]}
                              animation="scale-subtle"
                              theme="light-border"
                              placement="bottom-end"
                              interactive={true}
                              content={
                                <ul
                                  className="submenu"
                                  style={{
                                    width: "200px",
                                  }}
                                >
                                  <li
                                    className="Padding-all--0"
                                    onClick={() => setSettingsDropdown(false)}
                                  >
                                    <Link to="/plans" className="submenu-link">
                                      Plans
                                    </Link>
                                  </li>
                                  <li
                                    className="Padding-all--0"
                                    onClick={() => setSettingsDropdown(false)}
                                  >
                                    <Link
                                      to="/teams-and-permissions"
                                      className="submenu-link"
                                    >
                                      Teams &amp; Permissions
                                    </Link>
                                  </li>
                                </ul>
                              }
                            >
                              <button
                                className="UnstyledLink ButtonLink"
                                type="button"
                                onClick={() => setSettingsDropdown(true)}
                              >
                                <div
                                  aria-hidden="true"
                                  className="SVGInline SVGInline--cleaned SVG Icon Icon--notifications Icon-color Icon-color--gray Box-root Flex-flex Text-fontSize--20"
                                >
                                  <IoMdSettings />
                                </div>
                              </button>
                            </Tippy>
                          </div>
                          <div className="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Box-root Box-hideIfEmpty Margin-top--20 Margin-left--16">
                <div className="Box-root Flex-flex">
                  <div className="Box-root Flex-flex">
                    <div
                      aria-controls="menu2"
                      aria-haspopup="menu"
                      id="menu2-button"
                      tabIndex={-1}
                      style={{ outline: "none" }}
                    >
                      <div className="atd-NotificationsX-button">
                        <div
                          className="PressableCore PressableCore--radius--all PressableCore--width PressableCore--width--auto Box-root Flex-inlineFlex"
                          style={{
                            boxShadow:
                              "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px",
                          }}
                        >
                          <div className="PressableCore-base Box-root">
                            <Tippy
                              visible={openAppIcon}
                              offset={[0, 10]}
                              animation="scale-subtle"
                              theme="light-border"
                              onClickOutside={() => setOpenAppIcon(false)}
                              placement="bottom-end"
                              interactive={true}
                              content={
                                <div className="submenu1 Margin-vertical--8">
                                  <div className="SwitchApp__title">
                                    {isLoading ? (
                                      <h1>
                                        <Skeleton
                                          inline
                                          type="text"
                                          width="2.5em"
                                        />
                                      </h1>
                                    ) : (
                                      <h1>Switch to</h1>
                                    )}
                                  </div>
                                  {isLoading && (
                                    <>
                                      {[1, 2, 3].map((_, index) => (
                                        <div
                                          key={index}
                                          className="SwitchApp__item SwitchApp__item--outreach"
                                        >
                                          <div className="SwitchApp__item-inner">
                                            <Skeleton
                                              inline
                                              type="text"
                                              width="33px"
                                            />
                                            <div className="SwitchApp__item--name">
                                              <Skeleton
                                                inline
                                                type="text"
                                                width="8em"
                                              />
                                            </div>
                                            <div className="SwitchApp__item--label">
                                              <Skeleton
                                                inline
                                                type="text"
                                                width="33px"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                  <ul className="Switch_Dropdown">
                                    {!isLoading && products.results?.length > 0
                                      ? products.results
                                          .slice(0, 2)
                                          .map((product) => {
                                            return (
                                              <li
                                                key={product.product}
                                                onClick={() =>
                                                  (window.location =
                                                    product.url)
                                                }
                                              >
                                                <div className="dropdown_svg">
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html: product.svg_data,
                                                    }}
                                                  />
                                                </div>
                                                <div className="switch_product_title">
                                                  {product.product_full_name}
                                                </div>
                                              </li>
                                            );
                                          })
                                      : []}
                                  </ul>
                                </div>
                              }
                            >
                              <button
                                className="UnstyledLink ButtonLink"
                                type="button"
                                onClick={() => setOpenAppIcon(!openAppIcon)}
                              >
                                <div
                                  aria-hidden="true"
                                  className="SVGInline SVGInline--cleaned SVG Icon Icon--notifications Icon-color Icon-color--gray Box-root Flex-flex Text-fontSize--20"
                                >
                                  <CgMenuGridO />
                                </div>
                              </button>
                            </Tippy>
                          </div>
                          <div className="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="Box-root Box-hideIfEmpty Margin-top--16 Margin-left--16"
                style={{ pointerEvents: "auto" }}
              >
                <div className="Box-root">
                  <div className="Box-root Flex-flex">
                    <div className="Box-root Flex-flex">
                      <div
                        className="PressableCore PressableCore--radius--all PressableCore--width PressableCore--width--auto Box-root Flex-inlineFlex"
                        style={{
                          boxShadow:
                            "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px",
                        }}
                      >
                        <div className="PressableCore-base Box-root">
                          <Tippy
                            trigger="click"
                            offset={[0, 10]}
                            animation="scale-subtle"
                            theme="light-border"
                            placement="bottom-end"
                            interactive={true}
                            content={
                              <ul className="submenu">
                                <li
                                  className="submenu-link"
                                  onClick={logoutHandler}
                                >
                                  Logout
                                </li>
                              </ul>
                            }
                          >
                            <button
                              className="UnstyledLink ButtonLink"
                              type="button"
                            >
                              <div
                                aria-controls="menu4"
                                aria-haspopup="menu"
                                id="menu4-button"
                                aria-hidden="true"
                                className="SVGInline SVGInline--cleaned SVG Icon Icon--person Icon-color Icon-color--gray Box-root Flex-flex"
                              >
                                <svg
                                  aria-controls="menu4"
                                  aria-haspopup="menu"
                                  id="menu4-button"
                                  aria-hidden="true"
                                  className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--person-svg Icon-color-svg Icon-color--gray-svg"
                                  height={16}
                                  width={16}
                                  viewBox="0 0 16 16"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 11a5.698 5.698 0 0 0 3.9-1.537l1.76.66A3.608 3.608 0 0 1 16 13.5V15a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-1.5c0-1.504.933-2.85 2.34-3.378l1.76-.66A5.698 5.698 0 0 0 8 11zM7.804 0h.392a3.5 3.5 0 0 1 3.488 3.79l-.164 1.971a3.532 3.532 0 0 1-7.04 0l-.164-1.97A3.5 3.5 0 0 1 7.804 0z"
                                    fillRule="evenodd"
                                  />
                                </svg>
                              </div>
                            </button>
                          </Tippy>
                        </div>
                        <div className="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Top Nav area end */}
    </>
  );
};

export default Navbar;
