import React from "react";
import classnames from "classnames";

import styles from "./Skeleton.module.css";

function Skeleton({
  type,
  animationDelay = "0s",
  width,
  height,
  size,
  className,
  inline = false,
  fullWidth = false,
  rounded = false,
  style,
}) {
  const classes = classnames(styles.skeleton, className, {
    [`${styles[type]}`]: type,
    [styles.inline]: inline,
    [styles.fullWidth]: fullWidth,
    [styles.rounded]: rounded,
    [`${styles[size]}`]: size, // small or large
  });

  return (
    <span
      className={classes}
      style={{ animationDelay, width, height, ...style }}
    />
  );
}

export default Skeleton;
