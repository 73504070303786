import React from "react";
import { useState, useRef } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale-subtle.css";
import Modal from "react-modal";
import moment from "moment";

Modal.setAppElement("#root");

const TableItem = ({ member, onChangeUserRole, onDeleteUser }) => {
    const userRole = useRef();

    // Table Item Content
    const [showDropdown, setShowDropdown] = useState(false);
    const [showModal, setShowModal] = useState(false);

    // opening & closing of modal
    const editModalHandler = () => {
        setShowDropdown(false);
        setShowModal(true);
    };

    return (
        <>
            <tr key={member.id}>
                <td className="title2">{member.name}</td>
                <td>{member.email}</td>
                <td>
                    {member.role === 1 ? "Administrator" : "Restricted User"}
                </td>
                <td>
                    <div className="success">
                        {moment(member.modified).fromNow()}
                    </div>
                </td>
                <td>{member.status ? "Activate" : "De-Activate"}</td>
                <td className="dropdown">
                    <Tippy
                        visible={showDropdown}
                        onClickOutside={() => {
                            setShowDropdown(false);
                        }}
                        animation="scale-subtle"
                        theme="light-border"
                        placement="bottom-end"
                        interactive={true}
                        content={
                            <ul className="submenu">
                                <li onClick={editModalHandler}>Edit</li>
                                <li
                                    onClick={() => onDeleteUser(member.user_id)}
                                >
                                    <span className="red-txt">Delete</span>
                                </li>
                            </ul>
                        }
                    >
                        <div
                            className="dots btn"
                            onClick={() => setShowDropdown(!showDropdown)}
                        >
                            <svg
                                aria-hidden="true"
                                className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--more-svg Button-icon-svg Icon-color-svg Icon-color--gray600-svg"
                                height={12}
                                width={12}
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                    fillRule="evenodd"
                                />
                            </svg>
                        </div>
                    </Tippy>
                </td>
            </tr>
            {/* Modal */}
            <Modal
                isOpen={showModal}
                overlayClassName="ReactModal__Overlay"
                className="ReactModal__Content"
                onRequestClose={() => setShowModal(false)}
            >
                <div id="edituser" className="zoom-anim-dialog mfp-hide dialog">
                    <div className="pop-title Box-divider--light-bottom-1 Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween">
                        <span>Edit User Role</span>
                        <button
                            className="Modal__close-btn"
                            onClick={() => setShowModal(false)}
                        >
                            &times;
                        </button>
                    </div>
                    <div className="pd16">
                        <div className="FormField mgt1" id="rtype">
                            <div className="dropdown wt-100">
                                <select
                                    name="type"
                                    id="TypeSearchBox"
                                    className="iput"
                                    defaultValue={member.role}
                                    ref={userRole}
                                >
                                    <option value="1">Administrator</option>
                                    <option value="2">Restricted User</option>
                                </select>
                            </div>
                        </div>
                        <div className="mgl1 mgb5">
                            <div
                                className="Box-root Flex-flex mgt2"
                                style={{
                                    float: "right",
                                }}
                            >
                                <button
                                    className="wt-but Margin-right--8"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bl-but"
                                    onClick={() =>
                                        onChangeUserRole(
                                            member.user_id,
                                            userRole.current.value
                                        )
                                    }
                                >
                                    Change
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default TableItem;
