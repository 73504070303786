import axios from "axios";

import { getToken, getCompanyId } from "../utils/tokenUtilities";

// Create instance of axios
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${getToken()}`;
    config.params = config.params || {};

    if (getCompanyId()) {
        config.params["company_id"] = getCompanyId();
    }

    return config;
});

// export default axiosInstance;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getFeedbacks: (params) =>
        axiosInstance({
            method: "GET",
            url: "/feedback",
            params: {
                company_id: getCompanyId(),
                sort: "desc",
                ...params,
            },
        }),
    getIdeas: (params) =>
        axiosInstance({
            method: "GET",
            url: "/feedback",
            params: {
                company_id: getCompanyId(),
                sort: "desc",
                filter_type: "idea",
                ...params,
            },
        }),
    getIssues: (params) =>
        axiosInstance({
            method: "GET",
            url: "/feedback",
            params: {
                company_id: getCompanyId(),
                sort: "desc",
                filter_type: "issue",
                ...params,
            },
        }),
    getOthers: (params) =>
        axiosInstance({
            method: "GET",
            url: "/feedback",
            params: {
                company_id: getCompanyId(),
                sort: "desc",
                filter_type: "other",
                ...params,
            },
        }),
    getArchive: (params) =>
        axiosInstance({
            method: "GET",
            url: "/feedback",
            params: {
                company_id: getCompanyId(),
                sort: "desc",
                filter_type: "archive",
                ...params,
            },
        }),
    markArchive: (id) =>
        axiosInstance({
            method: "PUT",
            url: `/feedback/${id}`,
            params: {
                company_id: getCompanyId(),
            },
        }),
    deleteFeedback: (id) =>
        axiosInstance({
            method: "DELETE",
            url: `/feedback/${id}`,
            params: {
                company_id: getCompanyId(),
            },
        }),
    getWidgetDetails: () =>
        axiosInstance({
            method: "GET",
            url: "/feedback_widget",
            params: {
                company_id: getCompanyId(),
            },
        }),
    sendMailToDev: (payload) =>
        axiosInstance({
            method: "POST",
            url: "/sendmail",
            params: {
                project_id: getCompanyId(),
            },
            data: {
                email_id: payload.email,
            },
        }),
    getTeamMembers: () =>
        axiosInstance({
            method: "GET",
            url: "/team",
            params: {
                company_id: getCompanyId(),
                product: "feedback",
            },
        }),
    getSwictIconContent: () =>
        axiosInstance({
            method: "GET",
            url: "/product",
            params: {
                company_id: getCompanyId(),
                product: "feedback",
            },
        }),    
    sendInvitation: ({ user_id, email, role }) =>
        axiosInstance({
            method: "POST",
            url: "/team",
            params: {
                company_id: getCompanyId(),
                product: "feedback",
                user_id,
            },
            data: {
                email,
                role,
            },
        }),
    updateUserRole: (payload) =>
        axiosInstance({
            method: "PUT",
            url: `/team/${payload.user_id}`,
            params: {
                company_id: getCompanyId(),
                product: "feedback",
            },
            data: {
                role: payload.role,
            },
        }),
    deleteUser: (payload) =>
        axiosInstance({
            method: "DELETE",
            url: `/team/${payload.user_id}`,
            params: {
                company_id: getCompanyId(),
                product: "feedback",
            },
        }),
};
