import React, { useRef, useEffect } from 'react';

import FeedbackModal from './FeedbackModal';

const FeedbackOther = ({ onChange, setFeedbackOther }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className="otherbox">
      <FeedbackModal onCancel={() => setFeedbackOther(false)}>
        <textarea
          ref={inputRef}
          className="sloovi-FeedbackForm-textarea"
          placeholder="What do you want us to know?"
          onChange={e =>
            onChange(prevValue => ({
              ...prevValue,
              feedback_desc: e.target.value,
            }))
          }
        />
      </FeedbackModal>
    </div>
  );
};

export default FeedbackOther;
