/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";

import { FeedbackContext } from "./Feedback";

const FeedbackAction = ({ onCancel }) => {
	const location = useLocation();

	const { data, loading, onSend } = useContext(FeedbackContext);
	const [captured, setCaptured] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [isCaptured, setIsCaptured] = useState(false);

	useEffect(() => {
		window.addEventListener("message", function (event) {
			const parentData = JSON.parse(event.data);
			console.log("parentData", event);
			// captureScreen(parentData ? parentData.body : document.body);
		});
	});

	const captureScreen = body => {
		html2canvas(body, {
			ignoreElements: function (element) {
				/* Remove element with id="MyElementIdHere" */
				if ("sloovi-feedback" === element.id) {
					return true;
				}

				/* Remove all elements with class="MyClassNameHere" */
				if (element.classList.contains("sloovi-feedback")) {
					return true;
				}
			},
		}).then(async function (canvas) {
			setCaptured(true);
			setUploading(true);

			const url = new URLSearchParams(location.search);
			const projectId = url.get("project_id");
			const clientId = url.get("client_id");
			const userId = url.get("user_id");

			const response = await fetch(
				`https://api.feedback.sloovi.com/upload?project_id=${projectId}&client_id=${clientId}&user_id=${userId}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						data: canvas.toDataURL().replace("data:image/png;base64,", ""),
					}),
				}
			);
			const data = await response.json();
			setUploading(false);

			var a = document.createElement("a");
			// toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
			a.target = "_blank";
			a.href = data;
			a.style.backgroundImage = `url(${canvas.toDataURL("image/jpeg")})`;

			document.querySelector(".sloovi-fb-screenshot").prepend(a);
		});
	};

	const onCapture = e => {
		e.preventDefault();

		if (window.location !== window.parent.location) {
			console.log("onCapture");
			setIsCaptured(true);
			// window.addEventListener('message', function(event) {
			// 	const parentData = JSON.parse(event.data);
			// 	captureScreen(parentData ? parentData.body : document.body);
			// });
		} else {
			captureScreen(document.body);
			setIsCaptured(false);
		}
	};

	return (
		<div className='Box-root Box-background--offset Box-divider--light-top-1 Padding-all--12'>
			<div className='Box-root Flex-flex Flex-justifyContent--spaceBetween'>
				<div className='sloovi-fb sloovi-fb--cursor--pointer sloovi-fb--height--medium sloovi-fb--radius--all sloovi-fb--width sloovi-fb--width--auto PressableButton Button Button--color--white Box-root Flex-inlineFlex bshad'>
					<div className='sloovi-fb-base Box-root'>
						<button
							className='feedbackcancel UnstyledLink Button-element sloovi-fbContext Padding-horizontal--8 Padding-vertical--4 sloovi-fbContext--cursor--pointer sloovi-fbContext--display--inlineFlex sloovi-fbContext--fontLineHeight--20 sloovi-fbContext--fontSize--14 sloovi-fbContext--fontWeight--medium sloovi-fbContext--height sloovi-fbContext--height--medium sloovi-fbContext--radius--all sloovi-fbContext--width sloovi-fbContext--width--auto colr2'
							type='button'
							onClick={onCancel}
						>
							<div className='Button-align Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row posi1'>
								<div className='TextAligner Box-root flx4' />
								<div className='Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row Flex-justifyContent--center ccss4'>
									<span className='Button-label Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--block mgt-1'>
										<span>Cancel</span>
									</span>
								</div>
							</div>
						</button>
					</div>
				</div>
				<div className='Box-root Flex-flex Flex-justifyContent--spaceBetween'>
					{captured && !uploading && (
						<div className='sloovi-fb-screenshot' style={{ display: "none" }}>
							<button
								className='sloovi-fb-screenshot__close'
								style={{ top: "-3px", right: "-3px" }}
								onClick={() => setCaptured(false)}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width={6}
									height={6}
									fill='currentColor'
									viewBox='0 0 11 11'
								>
									<path d='M10.707 1.707A1 1 0 009.293.293l1.414 1.414zm-1.414 9a1 1 0 001.414-1.414l-1.414 1.414zM1.707.293A1 1 0 00.293 1.707L1.707.293zm-1.414 9a1 1 0 101.414 1.414L.293 9.293zm5.914-3.086l4.5-4.5L9.293.293l-4.5 4.5 1.414 1.414zm-1.414 0l4.5 4.5 1.414-1.414-4.5-4.5-1.414 1.414zm1.414-1.414l-4.5-4.5L.293 1.707l4.5 4.5 1.414-1.414zm-1.414 0l-4.5 4.5 1.414 1.414 4.5-4.5-1.414-1.414z' />
								</svg>
							</button>
						</div>
					)}
					{uploading ? (
						<button
							className='sloovi-fb-screenshot__loader'
							onClick={onCapture}
							style={{ display: "none" }}
						>
							<svg viewBox='0 0 24 24' className='Spinner-svg'>
								<g transform='translate(1 1)' fillRule='nonzero' fill='none'>
									<circle cx='11' cy='11' r='11'></circle>
									<path
										d='M10.998 22a.846.846 0 0 1 0-1.692 9.308 9.308 0 0 0 0-18.616 9.286 9.286 0 0 0-7.205 3.416.846.846 0 1 1-1.31-1.072A10.978 10.978 0 0 1 10.998 0c6.075 0 11 4.925 11 11s-4.925 11-11 11z'
										fill='currentColor'
									></path>
								</g>
							</svg>
						</button>
					) : (
						<button
							className='yet ccss41'
							onClick={onCapture}
							style={{ display: "none" }}
						>
							<svg
								width={20}
								height={17}
								viewBox='0 0 20 17'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
								className='tsform9'
							>
								<path
									d='M16.7432 2C16.7432 1.53977 16.3701 1.16667 15.9098 1.16667C15.4496 1.16667 15.0765 1.53977 15.0765 2L16.7432 2ZM18.4864 5.40992C18.9466 5.40992 19.3197 5.03682 19.3197 4.57658C19.3197 4.11635 18.9466 3.74325 18.4864 3.74325L18.4864 5.40992ZM13.3333 3.74325C12.873 3.74325 12.4999 4.11635 12.4999 4.57658C12.4999 5.03682 12.873 5.40992 13.3333 5.40992L13.3333 3.74325ZM15.0765 7.15316C15.0765 7.6134 15.4496 7.9865 15.9098 7.9865C16.3701 7.9865 16.7432 7.6134 16.7432 7.15316L15.0765 7.15316ZM16.7432 4.57658L16.7432 2L15.0765 2L15.0765 4.57658L16.7432 4.57658ZM15.9098 5.40992L18.4864 5.40992L18.4864 3.74325L15.9098 3.74325L15.9098 5.40992ZM15.9098 3.74325L13.3333 3.74325L13.3333 5.40992L15.9098 5.40992L15.9098 3.74325ZM15.0765 4.57658L15.0765 7.15316L16.7432 7.15316L16.7432 4.57658L15.0765 4.57658Z'
									fill='#718096'
								/>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M1.66667 6.99998C1.22464 6.99998 0.800716 7.17557 0.488155 7.48813C0.175595 7.80069 0 8.22462 0 8.66665V15.3333C0 15.7753 0.175595 16.1993 0.488155 16.5118C0.800716 16.8244 1.22464 17 1.66667 17H11.6667C12.1087 17 12.5326 16.8244 12.8452 16.5118C13.1577 16.1993 13.3333 15.7753 13.3333 15.3333V8.66665C13.3333 8.22462 13.1577 7.80069 12.8452 7.48813C12.5326 7.17557 12.1087 6.99998 11.6667 6.99998H10.345C10.124 6.99993 9.91208 6.9121 9.75583 6.75581L8.82166 5.82165C8.50918 5.50906 8.08532 5.33341 7.64333 5.33331H5.69C5.24801 5.33341 4.82415 5.50906 4.51167 5.82165L3.5775 6.75581C3.42126 6.9121 3.20933 6.99993 2.98833 6.99998H1.66667ZM6.66667 14.5C6.99497 14.5 7.32006 14.4353 7.62337 14.3097C7.92669 14.184 8.20229 13.9999 8.43443 13.7677C8.66658 13.5356 8.85073 13.26 8.97636 12.9567C9.102 12.6534 9.16666 12.3283 9.16666 12C9.16666 11.6717 9.102 11.3466 8.97636 11.0433C8.85073 10.74 8.66658 10.4644 8.43443 10.2322C8.20229 10.0001 7.92669 9.81592 7.62337 9.69028C7.32006 9.56464 6.99497 9.49998 6.66667 9.49998C6.00362 9.49998 5.36774 9.76337 4.8989 10.2322C4.43006 10.7011 4.16667 11.3369 4.16667 12C4.16667 12.663 4.43006 13.2989 4.8989 13.7677C5.36774 14.2366 6.00362 14.5 6.66667 14.5Z'
									fill='#718096'
								/>
							</svg>
						</button>
					)}
					<button
						className='bl-but wt-auto fbbtn'
						disabled={loading || data.feedback_desc.trim() === ""}
						onClick={onSend}
					>
						Send feedback
					</button>
				</div>
			</div>
		</div>
	);
};

export default FeedbackAction;
