import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

import FeedbackBox from "./FeedbackBox";
import FeedbackIssue from "./FeedbackIssue";
import FeedbackIdea from "./FeedbackIdea";
import FeedbackOther from "./FeedbackOther";
import FeedbackFooter from "./FeedbackFooter";
import FeedbackMessage from "./FeedbackMessage";

import "./Feedback.css";

export const FeedbackContext = React.createContext({
	data: {},
	loading: false,
	onChange: () => {},
	onSend: () => {},
});

const Feedback = () => {
	const location = useLocation();

	const [feedbackIssue, setFeedbackIssue] = useState(false);
	const [feedbackIdea, setFeedbackIdea] = useState(false);
	const [feedbackOther, setFeedbackOther] = useState(false);
	const [input, setInput] = useState({
		feedback_desc: "", // REQUIRED
		user_email: "", // OPTIONAL
		feedback_type: "", // REQUIRED
	});
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSendMoreFeedback = useCallback(() => {
		setSuccess(false);
		setFeedbackIssue(false);
		setFeedbackIdea(false);
		setFeedbackOther(false);
	}, []);

	useEffect(() => {
		handleSendMoreFeedback();

		return () => handleSendMoreFeedback();
	}, [handleSendMoreFeedback]);

	const handleSubmitFeedback = async e => {
		e.preventDefault();

		const url = new URLSearchParams(location.search);
		const projectId = url.get("project_id");
		const clientId = url.get("client_id");
		const userId = url.get("user_id");

		if (!userId) {
			return;
		}

		if (!projectId || !clientId) {
			alert("Invalid project_id or client_id");
			return;
		}

		setSuccess(false);
		setLoading(true);
		const response = await fetch(
			`https://api.feedback.sloovi.com/feedback?project_id=${projectId}&client_id=${clientId}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					feedback_title: "", // OPTIONAL
					feedback_desc: input.feedback_desc, // REQUIRED
					user_email: userId, // input.user_email, // OPTIONAL
					feedback_type: input.feedback_type, // REQUIRED
				}),
			}
		);
		const data = await response.json();

		if (data.status === "success") {
			setSuccess(true);
			setLoading(false);
		}

		if (data.status === "error") {
			setSuccess(false);
			setLoading(false);
		}
	};

	return (
		<FeedbackContext.Provider
			value={{
				loading,
				data: input,
				onChange: setInput,
				onSend: handleSubmitFeedback,
				onSendMoreFeedback: handleSendMoreFeedback,
			}}
		>
			<form id='sloovi-feedback'>
				<div className='sloovi-feedback'>
					{success ? (
						<FeedbackMessage />
					) : (
						<>
							{!feedbackIssue && !feedbackIdea && !feedbackOther ? (
								<FeedbackBox
									setFeedbackIssue={() => {
										setFeedbackIssue(true);
										setInput(prevInput => ({
											...prevInput,
											feedback_type: "issue",
										}));
									}}
									setFeedbackIdea={() => {
										setFeedbackIdea(true);
										setInput(prevInput => ({
											...prevInput,
											feedback_type: "idea",
										}));
									}}
									setFeedbackOther={() => {
										setFeedbackOther(true);
										setInput(prevInput => ({
											...prevInput,
											feedback_type: "other",
										}));
									}}
								/>
							) : null}
							{feedbackIssue && (
								<FeedbackIssue
									setFeedbackIssue={setFeedbackIssue}
									onChange={setInput}
								/>
							)}
							{feedbackIdea && (
								<FeedbackIdea
									setFeedbackIdea={setFeedbackIdea}
									onChange={setInput}
								/>
							)}
							{feedbackOther && (
								<FeedbackOther
									setFeedbackOther={setFeedbackOther}
									onChange={setInput}
								/>
							)}
						</>
					)}
					<FeedbackFooter />
				</div>
			</form>
		</FeedbackContext.Provider>
	);
};

export default Feedback;
