import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";

// ==== API Details ====
// https://stage.api.sloovi.com/feedback?sort=desc&page=1&filter_type=other
// company_id and token everytime required
// can be found in axiosInstance
// =====================

export const getOthersAsync = createAsyncThunk(
    "others/getOthersAsync",
    async (payload) => {
        // console.log(payload);
        const res = await axiosInstance.getOthers(payload.params);

        // console.log(res);
        if (res.data.code === 200) {
            return {
                others: res.data.results.data,
                hasMore: res.data.results.has_more,
            };
        } else {
            throw new Error("Server Error!");
        }
    }
);

// Delete a Single Feedback
export const deleteFeedbackAsync = createAsyncThunk(
    "others/deleteFeedbackAsync",
    async (id) => {
        console.log(id);
        await axiosInstance.deleteFeedback(id);

        return id;
    }
);

// Mark a feedback as Archived
export const markArchiveAsync = createAsyncThunk(
    "others/markArchiveAsync",
    async (id) => {
        await axiosInstance.markArchive(id);

        return id;
    }
);

export const otherSlice = createSlice({
    name: "others",
    initialState: {
        loading: false,
        hasErrors: false,
        others: [],
        hasMore: false,
    },
    reducers: {
        emptyOther: (state, action) => {
            state.others = [];
        },
    },
    extraReducers: {
        [getOthersAsync.pending]: (state, action) => {
            state.loading = true;
        },
        [getOthersAsync.fulfilled]: (state, action) => {
            state.others = [...state.others, ...action.payload.others];
            state.hasMore = action.payload.hasMore;
            state.loading = false;
            state.hasErrors = false;
        },
        [getOthersAsync.rejected]: (state, action) => {
            state.loading = false;
            state.hasErrors = true;
        },
        [deleteFeedbackAsync.fulfilled]: (state, action) => {
            state.others = state.others.filter(
                (item) => item.id !== action.payload
            );
        },
        [markArchiveAsync.fulfilled]: (state, action) => {
            state.others = state.others.filter(
                (item) => item.id !== action.payload
            );
        },
    },
});

export const { emptyOther } = otherSlice.actions;

export default otherSlice.reducer;
