import React from "react";

const IntegrationLink = ({ setShowModal }) => {
    return (
        <>
            {/* Integration link */}
            <div className="inte-link1">
                <h1>Welcome to your Sloovi Feedback dashboard! 👋</h1>
                <p>
                    Integrate the widget into your website and start collecting
                    customer feedback.
                </p>
                <button
                    className="bl-but wt-ft mgb1 mgt2"
                    onClick={() => setShowModal(true)}
                >
                    Integrate widget
                    <div
                        aria-hidden="true"
                        className="mgl-5 SVGInline SVGInline--cleaned SVG Icon Icon--arrowRight Button-icon Icon-color Icon-color--white Box-root Flex-flex"
                    >
                        <svg
                            aria-hidden="true"
                            className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--arrowRight-svg Button-icon-svg Icon-color-svg Icon-color--white-svg"
                            height={12}
                            width={12}
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.583 7L7.992 2.409A1 1 0 1 1 9.407.993l6.3 6.3a1 1 0 0 1 0 1.414l-6.3 6.3a1 1 0 0 1-1.415-1.416L12.583 9H1a1 1 0 1 1 0-2z"
                                fillRule="evenodd"
                            />
                        </svg>
                    </div>
                </button>
            </div>
        </>
    );
};

export default IntegrationLink;
