import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { setToken, setCompanyId } from "../utils/tokenUtilities";

// Login through form
export const loginUserAsync = createAsyncThunk(
    "auth/loginUserAsync",
    async (payload) => {
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/login`,
            payload
        );

        if (data.code === 200) {
            // SUCCESS

            // display success message
            toast.success(data.message);

            setToken(data.results.token);
            setCompanyId(data.results.company_id);

            // get company accounts associated with it.
            const { data: comp_data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/company`,
                {
                    headers: { Authorization: `Bearer ${data.results.token}` },
                    params: {
                        company_id: data.results.company_id,
                    },
                }
            );
            // get user's details
            const { data: user_data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/user`,
                {
                    headers: { Authorization: `Bearer ${data.results.token}` },
                    params: {
                        company_id: data.results.company_id,
                    },
                }
            );
            return {
                company_id: data.results.company_id,
                token: data.results.token,
                company_accounts: comp_data.results,
                user: user_data.results,
            };
        } else {
            // ERROR

            // display error message
            if (data.results && data.results.length > 0) {
                data.results.forEach((item) => {
                    toast.error(`${data.message} : ${item.msg}`);
                });
            } else {
                toast.error(data.message);
            }

            throw new Error(data.message);
        }
    }
);

// when somebody logins through cookies or query params,
// then we need to do this
export const getCompanyAccDataAsync = createAsyncThunk(
    "auth/getCompanyAccDataAsync",
    async (payload) => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/company`,
            {
                headers: { Authorization: `Bearer ${payload.token}` },
                params: {
                    company_id: payload.company_id,
                },
            }
        );

        if (data.code === 200) {
            // SUCCESS

            return {
                company_accounts: data.results,
            };
        } else {
            // ERROR

            // display error message
            if (data.results && data.results.length > 0) {
                data.results.forEach((item) => {
                    toast.error(`${data.message} : ${item.msg}`);
                });
            } else {
                toast.error(data.message);
            }

            throw new Error(data.message);
        }
    }
);

// Get User's Details
export const getUserDetailsAsync = createAsyncThunk(
    "auth/getUserDetailsAsync",
    async (payload) => {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/user`,
            {
                headers: { Authorization: `Bearer ${payload.token}` },
                params: {
                    company_id: payload.company_id,
                },
            }
        );

        if (data.code === 200) {
            // SUCCESS

            return {
                user: data.results,
            };
        } else {
            // ERROR

            // display error message
            if (data.results && data.results.length > 0) {
                data.results.forEach((item) => {
                    toast.error(`${data.message} : ${item.msg}`);
                });
            } else {
                toast.error(data.message);
            }

            throw new Error(data.message);
        }
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        loading: false,
        hasErrors: false,
        errorMessage: "",
        token: "",
        company_id: "",
        company_accounts: [],
        user: {},
    },
    reducers: {
        setCreadentials: (state, action) => {
            state.token = action.payload.token;
            state.company_id = action.payload.company_id;
        },
        removeCredentials: (state, action) => {
            state.token = null;
            state.company_id = null;
        },
    },
    extraReducers: {
        [loginUserAsync.pending]: (state, action) => {
            state.loading = true;
            state.hasErrors = false;
            state.errorMessage = "";
        },
        [loginUserAsync.fulfilled]: (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.errorMessage = "";
            state.company_id = action.payload.company_id;
            state.token = action.payload.token;
            state.company_accounts = action.payload.company_accounts;
            state.user = action.payload.user;
        },
        [loginUserAsync.rejected]: (state, action) => {
            state.loading = false;
            state.hasErrors = true;
            state.errorMessage = action.error.message;
        },
        // Get List of Company Accounts
        [getCompanyAccDataAsync.pending]: (state, action) => {
            state.hasErrors = false;
            state.errorMessage = "";
        },
        [getCompanyAccDataAsync.fulfilled]: (state, action) => {
            state.hasErrors = false;
            state.errorMessage = "";
            state.company_accounts = action.payload.company_accounts;
        },
        [getCompanyAccDataAsync.rejected]: (state, action) => {
            state.hasErrors = true;
            state.errorMessage = action.error.message;
        },
        // Get User's Details
        [getUserDetailsAsync.pending]: (state, action) => {
            state.hasErrors = false;
            state.errorMessage = "";
        },
        [getUserDetailsAsync.fulfilled]: (state, action) => {
            state.hasErrors = false;
            state.errorMessage = "";
            state.user = action.payload.user;
        },
        [getUserDetailsAsync.rejected]: (state, action) => {
            state.hasErrors = true;
            state.errorMessage = action.error.message;
        },
    },
});

export const { setCreadentials, removeCredentials } = authSlice.actions;

export default authSlice.reducer;
