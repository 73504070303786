import React from "react";

const BSIntegration = ({
    setActiveModal,
    setShowModal,
    project,
    client,
    user,
}) => {
    return (
        <div>
            <div id="integration-bootstrap" className="zoom-anim-dialog dialog">
                <div className="pop-title Box-divider--light-bottom-1 Flex-flex Flex-justifyContent--spaceBetween">
                    <button
                        className="int-back"
                        onClick={() => setActiveModal("main")}
                    >
                        <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="int-arrow"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </button>
                    <button
                        className="Modal__close-btn"
                        onClick={() => setShowModal(false)}
                    >
                        &times;
                    </button>
                </div>
                <div className="intbox">
                    <div className="intbox-align">
                        <h1 className="left">Usage with Bootstrap</h1>
                    </div>
                    <main className="notion">
                        <p className="notion-text">
                            To embed the feedback widget on your Bootstrap
                            website, first add the script tag to your HTML{" "}
                            <code className="notion-inline-code">
                                &lt;head&gt;
                            </code>
                            . Make sure to
                            <b>replace "..." with your project ID</b>, which you
                            can find on your dashboard!
                        </p>
                        <pre className="notion-code language-jsx css-18cntup">
                            <code className="language-jsx css-0">
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        html
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    {"\n"}
                                    {"   "}
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        head
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    {"\n"}
                                    {"      "}&lt;!-- ...other meta tags...
                                    --&gt;{"\n"}
                                    {"      "}
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        script
                                    </span>{" "}
                                    <span className="token attr-name">
                                        defer
                                    </span>{" "}
                                    <span className="token attr-name">src</span>
                                    <span className="token attr-value">
                                        <span className="token punctuation attr-equals">
                                            =
                                        </span>
                                        <span className="token punctuation">
                                            "
                                        </span>
                                        {`https://feedback.sloovi.com/sloovi-feedback.js?project_id=${project}&client_id=${client}&user_id=${user}`}
                                        <span className="token punctuation">
                                            "
                                        </span>
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text" />
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        script
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    {"\n"}
                                    {"   "}
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        head
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    {"\n"}
                                    {"   "}
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        body
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    &lt;!-- ...more HTML... --&gt;
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        body
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">{"\n"}</span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        html
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                            </code>
                        </pre>
                        <p className="notion-text">
                            Then, add a "Send feedback" button to your navbar
                            with the{" "}
                            <code className="notion-inline-code">
                                data-sloovi-feedback
                            </code>
                            attribute to trigger the widget on click:
                        </p>
                        <pre className="notion-code language-jsx css-18cntup">
                            <code className="language-jsx css-0">
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        ul
                                    </span>{" "}
                                    <span className="token attr-name">
                                        class
                                    </span>
                                    <span className="token attr-value">
                                        <span className="token punctuation attr-equals">
                                            =
                                        </span>
                                        <span className="token punctuation">
                                            "
                                        </span>
                                        navbar-nav
                                        <span className="token punctuation">
                                            "
                                        </span>
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    {"\n"}
                                    {"   "}&lt;!-- ...more nav items... --&gt;
                                    {"\n"}
                                    {"   "}
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        li
                                    </span>{" "}
                                    <span className="token attr-name">
                                        class
                                    </span>
                                    <span className="token attr-value">
                                        <span className="token punctuation attr-equals">
                                            =
                                        </span>
                                        <span className="token punctuation">
                                            "
                                        </span>
                                        nav-item
                                        <span className="token punctuation">
                                            "
                                        </span>
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    {"\n"}
                                    {"      "}
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        a
                                    </span>{" "}
                                    <span className="token attr-name">
                                        class
                                    </span>
                                    <span className="token attr-value">
                                        <span className="token punctuation attr-equals">
                                            =
                                        </span>
                                        <span className="token punctuation">
                                            "
                                        </span>
                                        nav-link
                                        <span className="token punctuation">
                                            "
                                        </span>
                                    </span>{" "}
                                    <span className="token attr-name">
                                        href
                                    </span>
                                    <span className="token attr-value">
                                        <span className="token punctuation attr-equals">
                                            =
                                        </span>
                                        <span className="token punctuation">
                                            "
                                        </span>
                                        #
                                        <span className="token punctuation">
                                            "
                                        </span>
                                    </span>{" "}
                                    <span className="token attr-name">
                                        data-sloovi-feedback
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    Send feedback
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        a
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    {"\n"}
                                    {"   "}
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        li
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">{"\n"}</span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        ul
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                            </code>
                        </pre>
                        {/* <p class="notion-text">Here is what the end result should look like:</p>
                              <figure class="notion-asset-wrapper" style="width: 474px;">
                                 <div style="position: relative;"><img class="notion-image-inset" alt="notion image" src="assets/images/Screenshot.png"></div>
                              </figure> */}
                    </main>
                </div>
            </div>
        </div>
    );
};

export default BSIntegration;
