import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";

// Importing Styles
import "./styles/index.css";
import "./styles/style.css";
import "./styles/custom.css";

import "react-dates/lib/css/_datepicker.css";
import "./styles/DateTime.css";

ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </StrictMode>,
    document.getElementById("root")
);
