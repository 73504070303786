import React from "react";

const OwnIntegration = ({
    setActiveModal,
    setShowModal,
    project,
    client,
    user,
}) => {
    return (
        <div>
            <div id="integration-own" className="zoom-anim-dialog dialog">
                <div className="pop-title Box-divider--light-bottom-1 Flex-flex Flex-justifyContent--spaceBetween">
                    <button
                        className="int-back"
                        onClick={() => setActiveModal("main")}
                    >
                        <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="int-arrow"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </button>
                    <button
                        className="Modal__close-btn"
                        onClick={() => setShowModal(false)}
                    >
                        &times;
                    </button>
                </div>
                <div className="intbox">
                    <div className="intbox-align">
                        <h1 className="left">Build your own form</h1>
                    </div>
                    <main className="notion">
                        <p className="notion-text">
                            You can also use Sloovi Feedback in{" "}
                            <em>headless</em>
                            mode, i.e. build your own form and submit the
                            feedback to our API endpoint. Send a POST request to{" "}
                            <a
                                href="https://feedback.sloovi/feedback"
                                className="notion-link"
                            >
                                https://api.feedback.sloovi/feedback
                            </a>{" "}
                            that looks like this:
                        </p>
                        <pre className="notion-code language-jsx css-18cntup">
                            <code className="language-jsx css-0">
                                <span className="token function">fetch</span>
                                <span className="token punctuation">(</span>
                                <span className="token string">
                                    'https://api.feedback.sloovi/feedback'
                                </span>
                                <span className="token punctuation">,</span>{" "}
                                <span className="token punctuation">{"{"}</span>
                                {"\n"}
                                {"   "}method
                                <span className="token operator">:</span>{" "}
                                <span className="token string">'POST'</span>
                                <span className="token punctuation">,</span>
                                {"\n"}
                                {"   "}headers
                                <span className="token operator">:</span>{" "}
                                <span className="token punctuation">{"{"}</span>
                                {"\n"}
                                {"      "}
                                <span className="token string">
                                    'Content-Type'
                                </span>
                                <span className="token operator">:</span>{" "}
                                <span className="token string">
                                    'application/json'
                                </span>
                                {"\n"}
                                {"   "}
                                <span className="token punctuation">{"}"}</span>
                                <span className="token punctuation">,</span>
                                {"\n"}
                                {"   "}body
                                <span className="token operator">:</span>{" "}
                                <span className="token constant">JSON</span>
                                <span className="token punctuation">.</span>
                                <span className="token function">
                                    stringify
                                </span>
                                <span className="token punctuation">(</span>
                                <span className="token punctuation">{"{"}</span>
                                {"\n"}
                                {"      "}projectId
                                <span className="token operator">:</span>{" "}
                                <span className="token string">
                                    "{project}"
                                </span>
                                <span className="token punctuation">,</span>
                                {"\n"}
                                {"      "}text
                                <span className="token operator">:</span>{" "}
                                feedbackText
                                <span className="token punctuation">,</span>
                                {"\n"}
                                {"      "}category
                                <span className="token operator">:</span>{" "}
                                <span className="token string">""</span>
                                <span className="token punctuation">
                                    ,
                                </span>{" "}
                                <span className="token comment">
                                    // Either "issue", "idea" or "other",
                                </span>
                                {"\n"}
                                {"      "}userId
                                <span className="token operator">:</span> "
                                {user}"
                                <span className="token punctuation">,</span>
                                {"\n"}
                                {"      "}metadata
                                <span className="token operator">:</span>{" "}
                                <span className="token punctuation">{"{"}</span>
                                <span className="token punctuation">{"}"}</span>
                                <span className="token punctuation">,</span>
                                {"\n"}
                                {"   "}
                                <span className="token punctuation">{"}"}</span>
                                <span className="token punctuation">)</span>
                                {"\n"}
                                <span className="token punctuation">{"}"}</span>
                                <span className="token punctuation">)</span>
                            </code>
                        </pre>
                        <div className="notion-blank">&nbsp;</div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default OwnIntegration;
