import React from 'react'

const Plans = () => {
    return (
        <>
            <div className="tab-pane active">
                <div className="Box-root Padding-bottom--20">
                    <div className="Card-root Card--radius--all Card--shadow--keyline Section Box-root Box-background--white">
                        <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
                            <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
                                <div
                                className="Box-root Flex-flex Flex-direction--column Flex-justifyContent--center"
                                style={{ flexBasis: 0, flexGrow: 1 }}
                                >
                                <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                    <span className="Text-color--dark Text-fontSize--20 Text-fontWeight--bold Text-lineHeight--28 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                    <span>Current Plan</span> <span className="yet">Free</span>
                                    </span>
                                </span>
                                </div>
                            </div>
                        </div>
                        {/* ContentHeader */}
                        <div className="ContentBlock Box-root Box-background--offset Box-divider--light-bottom-1 Padding-horizontal--8 Padding-vertical--2">
                            <div id="MerchantInfoForm">
                                <div className="atd-Fieldset-wrapper Box-root Margin-bottom--2">
                                <fieldset className="atd-Fieldset">
                                    <div className="atd-Fieldset-rows Padding-left--12">
                                    <div>10 / 25 feedback submissions</div>
                                    <div className="progress mgt1">
                                        <span className="progress-bar" style={{ width: "25%" }} />
                                    </div>
                                    <div className="title2 mgt2">
                                        Upgrade to Pro ($19 / mo) to unlock:
                                    </div>
                                    <div>Unlimited feedback submissions</div>
                                    <div className="title2 mgt2">
                                        Contact us about Enterprise to additionally unlock:
                                    </div>
                                    <div>
                                        Whitelabeling, service-level agreements and live chat support
                                    </div>
                                    </div>
                                </fieldset>
                                </div>
                            </div>
                        </div>
                        {/* Content Block */}

                        <div className="ContentFooter Box-root Box-background--white Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--stretch Flex-direction--row Flex-justifyContent--flexEnd">
                            <div className="Box-root Flex-flex flr">
                                <div className="Box-root Box-hideIfEmpty Margin-right--8 pointer1">
                                <a className="wt-but">
                                    <div className="Box-root"></div>
                                    Cancel
                                </a>
                                </div>
                                <div className="Box-root Box-hideIfEmpty pointer1">
                                <a className="bl-but">Upgrade to Pro</a>
                                </div>
                            </div>
                        </div>
                        {/* ContentFooter */}
                    </div>
                    {/* Card-root */}
                </div>
                {/* Box-root : Current Plan Free section */}

                <div className="Box-root Padding-bottom--20">
                    <div className="Card-root Card--radius--all Card--shadow--keyline Section Box-root Box-background--white">
                        <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
                            <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
                                <div
                                className="Box-root Flex-flex Flex-direction--column Flex-justifyContent--center"
                                style={{ flexBasis: 0, flexGrow: 1 }}
                                >
                                <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                    <span className="Text-color--dark Text-fontSize--20 Text-fontWeight--bold Text-lineHeight--28 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                    <span>Current Plan</span> <span className="now">Pro</span>
                                    </span>
                                </span>
                                </div>
                            </div>
                        </div>
                        {/* ContentHeader */}
                        <div className="ContentBlock Box-root Box-background--offset Box-divider--light-bottom-1 Padding-horizontal--8 Padding-vertical--2">
                            <div id="MerchantInfoForm">
                                <div className="atd-Fieldset-wrapper Box-root Margin-bottom--2">
                                <fieldset className="atd-Fieldset">
                                    <div className="atd-Fieldset-rows Padding-left--12" id="fbplan">
                                    <div>
                                        To change your plan, edit your credit card information or cancel
                                        your subscription click on <strong>Manage Subscription</strong>
                                    </div>
                                    <table className="table mb-0 wt-100 mgt2">
                                        <tbody>
                                        <tr>
                                            <th className="left">Monthly Limit</th>
                                            <th className="left">Cost</th>
                                            <th className="left">Collected Feedback</th>
                                        </tr>
                                        <tr>
                                            <td>Unlimited</td>
                                            <td>$19/month</td>
                                            <td>29</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </fieldset>
                                </div>
                            </div>
                        </div>
                        {/* ContentBlock */}

                        <div className="ContentFooter Box-root Box-background--white Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--stretch Flex-direction--row Flex-justifyContent--flexEnd">
                            <div className="Box-root Flex-flex flr">
                                <div className="Box-root Box-hideIfEmpty pointer1">
                                <a className="bl-but">Manage Subscription</a>
                                </div>
                            </div>
                        </div>
                        {/* ContentFooter */}
                    </div>
                    {/* Card-root */}
                </div>
                {/* Box-root : Current Plan Pro section */}
            </div>
            {/* tab-pane */}
        </>
    )
}

export default Plans
