import React from "react";
import { Route, Redirect } from "react-router-dom";
// import Cookies from "universal-cookie";
import { useSelector } from "react-redux";

import Layout from "../../hoc/Layout";

// const cookies = new Cookies();

function PrivateRoute({ component: Component, children, ...rest }) {
    const { token } = useSelector((state) => state.auth);

    return (
        <>
            <Route
                {...rest}
                render={(props) =>
                    // cookies.get("access_token") ? (
                    token ? (
                        <Layout {...props}>
                            {children ? children : <Component {...props} />}
                        </Layout>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        </>
    );
}

export default PrivateRoute;
