import React, { useRef, useEffect } from 'react';

import FeedbackModal from './FeedbackModal';

const FeedbackIssue = ({ onChange, setFeedbackIssue }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className="issuebox">
      <FeedbackModal onCancel={() => setFeedbackIssue(false)}>
        <textarea
          ref={inputRef}
          className="sloovi-FeedbackForm-textarea"
          placeholder="I noticed that..."
          onChange={e =>
            onChange(prevValue => ({
              ...prevValue,
              feedback_desc: e.target.value,
            }))
          }
        />
      </FeedbackModal>
    </div>
  );
};

export default FeedbackIssue;
