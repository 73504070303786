import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axiosInstance";

import IntegrationLink from "./IntegrationLink";
import IntegrationPopup from "./IntegrationPopup";
import HTMLIntegration from "./HTMLIntegration";
import ReactIntegration from "./ReactIntegration";
import BSIntegration from "./BSIntegration";
import OwnIntegration from "./OwnIntegration";

import Modal from "react-modal";
Modal.setAppElement("#root");

const Integration = () => {
    const [project, setProject] = useState("");
    const [client, setClient] = useState("");
    const [user, setUser] = useState("");

    useEffect(() => {
        // fetching the url, when this component mounts
        axiosInstance.getWidgetDetails().then((res) => {
            setProject(res.data.results.project_id);
            setClient(res.data.results.client_id);
            setUser(res.data.results.user_id);
        });
    }, []);

    const modals = {
        main: "main",
        html: "html",
        react: "react",
        bootstrap: "bootstrap",
        own: "own",
    };

    const [showModal, setShowModal] = useState(false);
    const [activeModal, setActiveModal] = useState(modals.main);

    useEffect(() => {
        if (!showModal) {
            setActiveModal("main");
        }
    }, [showModal]);

    const displayActiveModal = () => {
        switch (activeModal) {
            case modals.html:
                return (
                    <HTMLIntegration
                        setActiveModal={setActiveModal}
                        setShowModal={setShowModal}
                        project={project}
                        client={client}
                        user={user}
                    />
                );
            case modals.react:
                return (
                    <ReactIntegration
                        setActiveModal={setActiveModal}
                        setShowModal={setShowModal}
                        project={project}
                        client={client}
                        user={user}
                    />
                );
            case modals.bootstrap:
                return (
                    <BSIntegration
                        setActiveModal={setActiveModal}
                        setShowModal={setShowModal}
                        project={project}
                        client={client}
                        user={user}
                    />
                );
            case modals.own:
                return (
                    <OwnIntegration
                        setActiveModal={setActiveModal}
                        setShowModal={setShowModal}
                        project={project}
                        client={client}
                        user={user}
                    />
                );
            default:
                return (
                    <IntegrationPopup
                        setActiveModal={setActiveModal}
                        setShowModal={setShowModal}
                    />
                );
        }
    };

    return (
        <>
            {/* IntegrationLink */}
            <IntegrationLink setShowModal={setShowModal} />
            <Modal
                isOpen={showModal}
                overlayClassName="ReactModal__Overlay"
                className="ReactModal__Content"
                onRequestClose={() => setShowModal(false)}
            >
                {displayActiveModal()}
            </Modal>
        </>
    );
};

export default Integration;
