import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Error, Loading } from "../../components";
import {
    getTeamMembersAsync,
    updateUserRoleAsync,
    deleteUserAsync,
} from "../../redux/teamSlice";
import TeamsTable from "./Table/Table";
import Filters from "./TeamsFilter/Filters";
import NewUser from "./TeamsFilter/NewUser";

const TeamsAndPermissions = () => {
    const dispatch = useDispatch();
    const { teams, loading } = useSelector((state) => state.teams);
    const [teamMembers, setTeamMembers] = useState(teams);
    const [role, setRole] = useState(0);

    useEffect(() => {
        dispatch(getTeamMembersAsync());
    }, [dispatch]);

    useEffect(() => {
        if (role) {
            // set equal to teams.filter
            const data = teams.filter(
                (member) => member.role === parseInt(role)
            );
            setTeamMembers(data);
        } else {
            // set equal to teams
            setTeamMembers(teams);
        }
    }, [role, teams]);

    const onChangeUserRoleHandler = (user_id, role) => {
        dispatch(
            updateUserRoleAsync({
                user_id,
                role,
            })
        );
    };

    const onDeleteUserHandler = (user_id) => {
        dispatch(deleteUserAsync({user_id}));
    };

    return (
        <>
            <div className="Box-root Padding-top--16 Padding-bottom--8 Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween">
                <span className="Text-color--dark Text-fontSize--28 Text-fontWeight--bold Text-lineHeight--32 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                    <span>Team and Permissions</span>
                </span>
                <div className="Box-root" style={{ pointerEvents: "none" }}>
                    <div
                        id="tm"
                        className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
                        style={{ marginLeft: "-8px", marginTop: "-8px" }}
                    >
                        <Filters setRole={setRole} />
                        <NewUser />
                    </div>
                </div>
            </div>

            <TeamsTable
                teamMembers={teamMembers}
                onChangeUserRole={onChangeUserRoleHandler}
                onDeleteUser={onDeleteUserHandler}
            />
            {loading ? <Loading /> : null}
        </>
    );
};

export default TeamsAndPermissions;
