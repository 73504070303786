import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import axiosInstance from "../utils/axiosInstance";

// Get all the team members
export const getTeamMembersAsync = createAsyncThunk(
    "teams/getTeamMembersAsync",
    async () => {
        const res = await axiosInstance.getTeamMembers();
        return {
            teams: res.data.results.data,
        };
    }
);

// Update User Roles
export const updateUserRoleAsync = createAsyncThunk(
    "teams/updateUserRoleAsync",
    async (payload) => {
        const res = await axiosInstance.updateUserRole(payload);
        if (res.data.code === 200) {
            return payload;
        } else {
            toast.error(res.data.message);
            throw new Error(res.data.message);
        }
    }
);

// Delete User
export const deleteUserAsync = createAsyncThunk(
    "teams/deleteUserAsync",
    async (payload) => {
        const res = await axiosInstance.deleteUser(payload);
        if (res.data.code === 200) {
            return payload;
        } else {
            toast.error(res.data.message);
            throw new Error(res.data.message);
        }
    }
);

export const teamSlice = createSlice({
    name: "teams",
    initialState: {
        loading: false,
        hasErrors: false,
        errorMsg: "",
        teams: [],
    },
    // reducers: {},
    extraReducers: {
        [getTeamMembersAsync.pending]: (state, action) => {
            state.loading = true;
            state.hasErrors = false;
            state.errorMsg = "";
        },
        [getTeamMembersAsync.fulfilled]: (state, action) => {
            state.teams = action.payload.teams;
            state.loading = false;
            state.hasErrors = false;
        },
        [getTeamMembersAsync.rejected]: (state, action) => {
            state.loading = false;
            state.hasErrors = true;
            state.errorMsg = action.error.message;
        },
        [updateUserRoleAsync.pending]: (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.errorMsg = "";
        },
        [updateUserRoleAsync.fulfilled]: (state, action) => {
            state.teams = state.teams.map(member => {
                if(member.user_id === action.payload.user_id) {
                    member.role = action.payload.role;
                }
            });
            state.loading = false;
            state.hasErrors = false;
            state.errorMsg = "";
        },
        [updateUserRoleAsync.rejected]: (state, action) => {
            state.loading = false;
            state.hasErrors = true;
            state.errorMsg = action.error.message;
        },
        [deleteUserAsync.pending]: (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.errorMsg = "";
        },
        [deleteUserAsync.fulfilled]: (state, action) => {
            state.teams = state.teams.filter(member => member.user_id !== action.payload.user_id);
            state.loading = false;
            state.hasErrors = false;
            state.errorMsg = "";
        },
        [deleteUserAsync.rejected]: (state, action) => {
            state.loading = false;
            state.hasErrors = true;
            state.errorMsg = action.error.message;
        },
    },
});

// export const { filterTeamMembers } = teamSlice.actions;

export default teamSlice.reducer;
