import React from "react";
import { useSelector } from "react-redux";
import Gravatar from "../../images/avt.jpg";

const MyProfile = () => {
    const {
        user: { name, email, company, icon },
    } = useSelector((state) => state.auth);

    return (
        <>
            <div className="tab-pane active">
                <div className="Box-root Padding-bottom--20">
                    <div className="Card-root Card--radius--all Card--shadow--keyline Section Box-root Box-background--white">
                        <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
                            <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
                                <div
                                    className="Box-root Flex-flex Flex-direction--column Flex-justifyContent--center"
                                    style={{ flexBasis: 0, flexGrow: 1 }}
                                >
                                    <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                        <span className="Text-color--dark Text-fontSize--20 Text-fontWeight--bold Text-lineHeight--28 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                            <span>Change Profile</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* ContentHeader */}
                        <div className="ContentBlock Box-root Box-background--offset Box-divider--light-bottom-1 Padding-horizontal--8 Padding-vertical--2">
                            <div id="MerchantInfoForm">
                                <div className="atd-Fieldset-wrapper Box-root Margin-bottom--2">
                                    <fieldset className="atd-Fieldset">
                                        <div className="atd-Fieldset-rows">
                                            <div className="atd-Fieldset-row">
                                                <label className="atd-Fieldset-label">
                                                    Name
                                                </label>
                                                <div className="atd-Fieldset-fields">
                                                    <input
                                                        name="fname"
                                                        className="iput acc-input"
                                                        placeholder={name}
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="atd-Fieldset-row">
                                                <label className="atd-Fieldset-label">
                                                    Email Address
                                                </label>
                                                <div className="atd-Fieldset-fields">
                                                    <input
                                                        name="email"
                                                        className="iput acc-input"
                                                        placeholder={email}
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="atd-Fieldset-row">
                                                <label className="atd-Fieldset-label">
                                                    Company
                                                </label>
                                                <div className="atd-Fieldset-fields">
                                                    <input
                                                        name="company"
                                                        className="iput acc-input"
                                                        placeholder={company}
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="atd-Fieldset-row">
                                                <label className="atd-Fieldset-label mgt1">
                                                    Profile Image
                                                </label>
                                                <div className="bs-Fieldset-field">
                                                    <p className="bs-Fieldset-explanation">
                                                        <img
                                                            src={icon}
                                                            alt={name}
                                                            className="acc-avt"
                                                        />
                                                    </p>
                                                    <div
                                                        style={{
                                                            marginLeft: 70,
                                                            marginTop: 6,
                                                        }}
                                                    >
                                                        You can change your
                                                        profile image at
                                                        <a
                                                            className="UnstyledLink InlineLink Text-color--blue"
                                                            href="#"
                                                        >
                                                            {" "}
                                                            Gravatar.com
                                                        </a>
                                                        , using
                                                        <br />
                                                        the email address listed
                                                        below.
                                                    </div>
                                                    <p />
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        {/* ContentBlock */}
                        <div className="ContentFooter Box-root Box-background--white Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--stretch Flex-direction--row Flex-justifyContent--flexEnd">
                            <div className="Box-root Flex-flex flr">
                                <div className="Box-root Box-hideIfEmpty Margin-right--8 pointer1">
                                    <a className="wt-but">
                                        <div className="Box-root"></div>
                                        Cancel
                                    </a>
                                </div>
                                <div className="Box-root Box-hideIfEmpty pointer1">
                                    <a className="bl-but">Save Profile</a>
                                </div>
                            </div>
                        </div>
                        {/* ContentFooter */}
                    </div>
                    {/* Card-root */}
                </div>
                {/* Box-root : Change Profile Section */}

                <div className="Box-root Padding-bottom--20">
                    <div className="Card-root Card--radius--all Card--shadow--keyline Section Box-root Box-background--white">
                        <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
                            <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
                                <div
                                    className="Box-root Flex-flex Flex-direction--column Flex-justifyContent--center"
                                    style={{ flexBasis: 0, flexGrow: 1 }}
                                >
                                    <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                        <span className="Text-color--dark Text-fontSize--20 Text-fontWeight--bold Text-lineHeight--28 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                            <span>Change Password</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="ContentBlock Box-root Box-background--offset Box-divider--light-bottom-1 Padding-horizontal--8 Padding-vertical--2">
                            <div id="MerchantInfoForm">
                                <div className="atd-Fieldset-wrapper Box-root Margin-bottom--2">
                                    <fieldset className="atd-Fieldset">
                                        <div className="atd-Fieldset-rows">
                                            <div className="atd-Fieldset-row">
                                                <label className="atd-Fieldset-label">
                                                    New Password
                                                </label>
                                                <div className="atd-Fieldset-fields">
                                                    <input
                                                        name="npass"
                                                        className="iput acc-input"
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="atd-Fieldset-row">
                                                <label className="atd-Fieldset-label">
                                                    Confirm New Password
                                                </label>
                                                <div className="atd-Fieldset-fields">
                                                    <input
                                                        name="cpass"
                                                        className="iput acc-input"
                                                        type="text"
                                                    />
                                                    <p className="bs-Fieldset-explanation Margin-top--8 Margin-left--8">
                                                        For security, you will
                                                        be asked to confirm your
                                                        password.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div className="ContentFooter Box-root Box-background--white Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--stretch Flex-direction--row Flex-justifyContent--flexEnd">
                            <div className="Box-root Flex-flex flr">
                                <div className="Box-root Box-hideIfEmpty Margin-right--8 pointer1">
                                    <a className="wt-but">
                                        <div className="Box-root"></div>
                                        Cancel
                                    </a>
                                </div>
                                <div className="Box-root Box-hideIfEmpty pointer1">
                                    <a className="bl-but">Save Password</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Card-root */}
                </div>
                {/* Box-root : Change Password Section */}

                <div className="Box-root Padding-bottom--20 mgb5">
                    <div className="Card-root Card--radius--all Card--shadow--keyline Section Box-root Box-background--white">
                        <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
                            <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
                                <div
                                    className="Box-root Flex-flex Flex-direction--column Flex-justifyContent--center"
                                    style={{ flexBasis: 0, flexGrow: 1 }}
                                >
                                    <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                        <span className="Text-color--dark Text-fontSize--20 Text-fontWeight--bold Text-lineHeight--28 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                            <span>Project Id</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="ContentBlock Box-root Box-background--offset Box-divider--light-bottom-1 Padding-horizontal--8 Padding-vertical--2">
                            <div id="MerchantInfoForm">
                                <div className="atd-Fieldset-wrapper Box-root Margin-bottom--2">
                                    <fieldset className="atd-Fieldset">
                                        <div className="atd-Fieldset-rows">
                                            <div className="atd-Fieldset-row">
                                                <label className="atd-Fieldset-label">
                                                    Id
                                                </label>
                                                <div className="atd-Fieldset-fields">
                                                    <input
                                                        name="api"
                                                        className="iput acc-input"
                                                        placeholder="18234a2cf1a6a1"
                                                        defaultValue="18234a2cf1a6a1"
                                                        type="text"
                                                        readOnly
                                                    />
                                                    {/* <p className="bs-Fieldset-explanation Margin-top--8 Margin-left--8">This API allows Our customers with an active subscription to see their submitted requests and to create new requests.</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div className="ContentFooter Box-root Box-background--white Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--stretch Flex-direction--row Flex-justifyContent--flexEnd">
                            <div className="Box-root Flex-flex flr">
                                <div className="Box-root Box-hideIfEmpty pointer1">
                                    <a className="bl-but">Save</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Box-root : Project Id Section */}
            </div>
            {/* tab-pane */}
        </>
    );
};

export default MyProfile;
