import React, { useEffect, useState } from "react";
import { Table, Loading, Error } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import {
    emptyIdea,
    getIdeasAsync,
    deleteFeedbackAsync,
    markArchiveAsync,
} from "../../redux/ideaSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import FilterBar from "../../components/FilterBar/FilterBar";

const FeedbackIdea = () => {
    const dispatch = useDispatch();
    const { ideas, loading, hasErrors, hasMore } = useSelector(
        (state) => state.ideas
    );

    const [page, setPage] = useState(1);

    const [dateFilter, setDateFilter] = useState("all_time");

    // When this component first loads
    useEffect(() => {
        dispatch(emptyIdea());
        setPage(1);
        dispatch(
            getIdeasAsync({
                params: {
                    page: page,
                    date: dateFilter,
                },
            })
        );
    }, [dateFilter, dispatch, page]);

    // After the first load
    useEffect(() => {
        if (page !== 1) {
            dispatch(
                getIdeasAsync({
                    params: {
                        page: page,
                        date: dateFilter,
                    },
                })
            );
        }
    }, [page]);

    const dateFilterHandler = (filterType) => {
        setDateFilter(filterType);
        // console.log("dateFilterHandler called!", filterType);
    };

    return (
        <>
            <div className="Box-root Padding-top--16 Padding-bottom--8 Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween">
                <span className="Text-color--dark Text-fontSize--28 Text-fontWeight--bold Text-lineHeight--32 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                    <span>Ideas</span>
                </span>
            </div>
            <FilterBar dateFilterHandler={dateFilterHandler} />
            {hasErrors && <Error />}
            {ideas?.length > 0 ? (
                <InfiniteScroll
                    dataLength={ideas.length}
                    hasMore={hasMore}
                    next={() => setPage((page) => page + 1)}
                    // scrollableTarget={document.querySelector("#allfeedback")}
                    scrollThreshold="10px"
                >
                    <Table
                        items={ideas}
                        deleteFeedbackAsync={deleteFeedbackAsync}
                        markArchiveAsync={markArchiveAsync}
                    />
                </InfiniteScroll>
            ) : null}
            {loading && <Loading />}
        </>
    );
};

export default FeedbackIdea;
