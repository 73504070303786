import React from "react";
import { SlooviFeedback } from "sloovi-feedback";

const Widget = () => {
    return (
        <>
            <SlooviFeedback
                projectId="project_a5931b24ad854c68b8e5af1057a10ec4"
                clientId="client_7442521eaf0f4995bdbf9cdb00e94c14"
            >
                <div className="Box-root Box-hideIfEmpty Margin-top--16 Margin-left--16">
                    <button className="widget-btn UnstyledLink ButtonLink Flex-flex">
                        <svg
                            aria-hidden="true"
                            className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--feedback-svg Icon-color-svg Icon-color--gray-svg"
                            fill="currentColor"
                            height="16"
                            width="16"
                            viewBox="0 0 19 19"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ margin: "1px 8px 0 0" }}
                        >
                            <path
                                d="M2.666 11.995a304.44 304.44 0 0 1-1.841-.776s-.41-.14-.558-.638c-.148-.498-.187-1.058 0-1.627.187-.57.558-.735.558-.735s9.626-4.07 13.64-5.43c.53-.179 1.18-.156 1.18-.156C17.607 2.702 19 6.034 19 9.9c0 3.866-1.62 6.808-3.354 6.84 0 0-.484.1-1.18-.135-2.189-.733-5.283-1.946-7.971-3.035-.114-.045-.31-.13-.338.177v.589c0 .56-.413.833-.923.627l-1.405-.566c-.51-.206-.923-.822-.923-1.378v-.63c.018-.29-.162-.362-.24-.394zM15.25 15.15c1.367 0 2.475-2.462 2.475-5.5s-1.108-5.5-2.475-5.5-2.475 2.462-2.475 5.5 1.108 5.5 2.475 5.5z"
                                fill="evenodd"
                            ></path>
                        </svg>{" "}
                        Feedback?
                    </button>
                </div>
            </SlooviFeedback>
        </>
    );
};

export default Widget;
