import React from 'react';

import SendMoreFeedbackButton from '../SendMoreFeedbackButton/SendMoreFeedbackButton';

const FeedbackMessage = () => {
  return (
    <div className="thanksbox">
      <div className="sloovi-FeedbackModal">
        <div className="sloovi-FeedbackForm">
          <div className="sloovi-FeedbackForm-step sloovi-FeedbackForm-step--success">
            <div className="sloovi-FeedbackForm-successIcon" />
            <h1 className="sloovi-FeedbackForm-successTitle">
              <span>Thanks for your time!</span>
            </h1>
            <p className="sloovi-FeedbackForm-successDescription">
              <span>We value your feedback.</span>
            </p>
            <SendMoreFeedbackButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackMessage;
