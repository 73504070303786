import React from "react";
import TableItem from "./TableItem";

const TableList = ({ items, deleteFeedbackAsync, markArchiveAsync }) => {
    return (
        <ul className="accordion-menu">
            {items.map((item) => (
                <TableItem
                    key={item.id}
                    item={item}
                    deleteFeedbackAsync={deleteFeedbackAsync}
                    markArchiveAsync={markArchiveAsync}
                />
            ))}
        </ul>
    );
};

export default TableList;
