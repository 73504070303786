import React from 'react';

import logo from '../../../images/outreach-logo.svg';

const FeedbackFooter = () => (
  <div
    className="center"
    style={{ fontSize: 11, paddingBottom: 8, paddingTop: 5 }}
  >
    <img
      src={logo}
      width={15}
      style={{ verticalAlign: 'bottom' }}
      alt="Sloovi"
    />{' '}
    Widget by Sloovi
  </div>
);

export default FeedbackFooter;
