import React, { useEffect, useState } from "react";
import { Table, Loading, Error } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import {
    emptyFeedback,
    getFeedbacksAsync,
    deleteFeedbackAsync,
    markArchiveAsync,
} from "../../redux/feedbackSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import FilterBar from "../../components/FilterBar/FilterBar";
import Integration from "./integration";

const FeedbackAll = () => {
    const dispatch = useDispatch();
    const { feedbacks, loading, hasErrors, hasMore } = useSelector(
        (state) => state.feedbacks
    );

    const [page, setPage] = useState(1);

    const [dateFilter, setDateFilter] = useState("all_time");

    // When this component first loads
    useEffect(() => {
        dispatch(emptyFeedback());
        setPage(1);
        dispatch(
            getFeedbacksAsync({
                params: {
                    page: page,
                    date: dateFilter,
                },
            })
        );
    }, [dateFilter, dispatch, page]);

    // After the first load
    useEffect(() => {
        if (page !== 1) {
            dispatch(
                getFeedbacksAsync({
                    params: {
                        page: page,
                        date: dateFilter,
                    },
                })
            );
        }
    }, [dateFilter, dispatch, page]);

    const dateFilterHandler = (filterType) => {
        setDateFilter(filterType);
        // console.log("dateFilterHandler called!", filterType);
    };

    return (
        <>
            {/* Integration Section & Modal */}
            <Integration />

            <div className="Box-root Padding-top--16 Padding-bottom--8 Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween">
                <span className="Text-color--dark Text-fontSize--28 Text-fontWeight--bold Text-lineHeight--32 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                    <span>Feedback</span>
                </span>
            </div>
            <FilterBar dateFilterHandler={dateFilterHandler} />
            {hasErrors && <Error />}
            {feedbacks?.length > 0 ? (
                <InfiniteScroll
                    dataLength={feedbacks.length}
                    hasMore={hasMore}
                    next={() => setPage((page) => page + 1)}
                    // scrollableTarget={document.querySelector("#allfeedback")}
                    scrollThreshold="10px"
                >
                    <Table
                        items={feedbacks}
                        deleteFeedbackAsync={deleteFeedbackAsync}
                        markArchiveAsync={markArchiveAsync}
                    />
                </InfiniteScroll>
            ) : null}
            {loading && <Loading />}
        </>
    );
};

export default FeedbackAll;
