import React, { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    loginUserAsync,
    getCompanyAccDataAsync,
    getUserDetailsAsync,
    setCreadentials,
} from "../../redux/authSlice";
import { setToken, setCompanyId } from "../../utils/tokenUtilities";
import { RiFeedbackFill } from "react-icons/ri";

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { loading, hasErrors } = useSelector((state) => state.auth);

    /*
        There are three ways to login into this app
        either through submitting the form and getting
        back token & company_id or by passing it as query params
        or if user already uses any sloovi product
        i.e. cookies will already contain credentials
    */

    // check if credentials are passed as query params
    const query = new URLSearchParams(useLocation().search);
    const company_id = query.get("company_id");
    const token = query.get("token");
    if (company_id && token) {
        setToken(token);
        setCompanyId(company_id);
        dispatch(
            setCreadentials({
                company_id,
                token,
            })
        );
        dispatch(
            getCompanyAccDataAsync({
                token,
                company_id,
            })
        );
        dispatch(
            getUserDetailsAsync({
                token,
                company_id,
            })
        );
        history.push("/feedback/all");
    }

    const emailRef = useRef();
    const passRef = useRef();

    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(
            loginUserAsync({
                email: emailRef.current.value,
                password: passRef.current.value,
            })
        );
    };

    return (
        <div className="login-wrapper">
            <p className="login-icon">
                <RiFeedbackFill />
            </p>
            <h4>
                <b>Please log into your account</b>
            </h4>
            <form className="login-form" onSubmit={submitHandler}>
                <div className="Margin-bottom--16">
                    <label htmlFor="email">
                        <b>Email Address</b>
                    </label>
                    <input
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="e.g. email@domain.com"
                        ref={emailRef}
                        required
                    />
                </div>
                <div className="Margin-bottom--16">
                    <label htmlFor="password">
                        <b>Password</b>
                    </label>
                    <input
                        type="password"
                        id="password"
                        className="form-control"
                        ref={passRef}
                        required
                    />
                </div>
                <div className="Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween Padding-top--8">
                    <a href="#" className="text-primary">
                        Forgot your password?
                    </a>
                    <button type="submit">
                        {loading ? (
                            <span className="Padding-horizontal--16">
                                <i className="fa fa-spinner fa-pulse"></i>
                                <span className="sr-only">Loading...</span>
                            </span>
                        ) : (
                            "Log In"
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Login;
