import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import Modal from "react-modal";
Modal.setAppElement("#root");

const NewUser = () => {
    const [showModal, setShowModal] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");

    // to reset the form
    useEffect(() => {
        if (!showModal) {
            setIsSent(false);
            setMessage("");
        }
    }, [showModal]);

    const emailRef = useRef();
    const roleRef = useRef();

    const onSubmitHandler = async () => {
        if (!isSent) {
            setIsLoading(true);
            const res = await axiosInstance.sendInvitation({
                email: emailRef.current.value,
                role: parseInt(roleRef.current.value),
            });
            setIsLoading(false);
            if (res.data.code === 200 || res.data.code === 201) {
                setIsSent(true);
            } else {
                setIsSent(false);
                setMessage(res.data.message);
            }
            emailRef.current.value = "";
            roleRef.current.value = "1";
        }
    };

    return (
        <>
            <div
                className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                style={{ pointerEvents: "auto" }}
            >
                <button
                    onClick={() => setShowModal(!showModal)}
                    className="popup-with-zoom-anim bl-but"
                >
                    <div className="Box-root Padding-right--8">
                        <div
                            aria-hidden="true"
                            className="SVGInline SVGInline--cleaned SVG Icon Icon--add Button-icon Icon-color Icon-color--white Box-root Flex-flex"
                            style={{
                                transform: "translateY(1.1px)",
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--add-svg Button-icon-svg Icon-color-svg Icon-color--white-svg"
                                height={12}
                                width={12}
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9 7h6a1 1 0 0 1 0 2H9v6a1 1 0 0 1-2 0V9H1a1 1 0 1 1 0-2h6V1a1 1 0 1 1 2 0z"
                                    fillRule="evenodd"
                                />
                            </svg>
                        </div>
                    </div>
                    New User
                </button>
            </div>
            <Modal
                isOpen={showModal}
                overlayClassName="ReactModal__Overlay"
                className="ReactModal__Content"
                onRequestClose={() => setShowModal(false)}
            >
                <div id="adduser" className="zoom-anim-dialog mfp-hide dialog">
                    <div className="pop-title Box-divider--light-bottom-1 Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween">
                        <span>Invite new users</span>
                        <button
                            className="Modal__close-btn"
                            onClick={() => setShowModal(false)}
                        >
                            &times;
                        </button>
                    </div>
                    <div className="pd16">
                        <div>
                            Enter the email addresses of the users you'd like to
                            invite for just $9/month, and choose the role they
                            should have.
                        </div>
                        {message && (
                            <div className="alert alert-danger Margin-horizontal--auto Margin-bottom--12">
                                {message}
                            </div>
                        )}
                        <div className="FormField mgt2" id="rtype">
                            <div className="dropdown wt-100">
                                {isSent ? (
                                    <>
                                        <input
                                            name="email"
                                            className="iput Margin-right--8"
                                            placeholder="Enter user's email id"
                                            type="email"
                                            ref={emailRef}
                                            disabled
                                        />
                                        <select
                                            name="role"
                                            id="TypeSearchBox"
                                            className="iput"
                                            ref={roleRef}
                                            disabled
                                        >
                                            <option value="1">
                                                Administrator
                                            </option>
                                            <option value="2">
                                                Restricted User
                                            </option>
                                        </select>
                                    </>
                                ) : (
                                    <>
                                        <input
                                            name="email"
                                            className="iput Margin-right--8"
                                            placeholder="Enter user's email id"
                                            type="email"
                                            ref={emailRef}
                                            required
                                        />
                                        <select
                                            name="role"
                                            id="TypeSearchBox"
                                            className="iput"
                                            ref={roleRef}
                                            required
                                        >
                                            <option value="1">
                                                Administrator
                                            </option>
                                            <option value="2">
                                                Restricted User
                                            </option>
                                        </select>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="mgt2 notesbox">
                            <div className="notestitle">
                                Note: For Administrator
                            </div>
                            <div className="notesdetails">
                                Contains all available permissions for owners
                                and administrators.
                            </div>
                        </div>
                        <div className="mgl1 mgb5">
                            <div
                                className="Box-root Flex-flex mgt2"
                                style={{ float: "right" }}
                            >
                                <button
                                    className="wt-but Margin-right--8"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>

                                {isSent ? (
                                    <button className="bl-but">
                                        <i className="fa fa-check"></i>
                                        &nbsp;Invited
                                    </button>
                                ) : (
                                    <button
                                        className="bl-but"
                                        onClick={onSubmitHandler}
                                    >
                                        {isLoading ? (
                                            <span className="Padding-horizontal--16">
                                                <i className="fa fa-spinner fa-pulse"></i>
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </span>
                                        ) : (
                                            "Invite"
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* partial */}
        </>
    );
};

export default NewUser;
