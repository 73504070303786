import React from "react";

const ReactIntegration = ({
    setActiveModal,
    setShowModal,
    project,
    client,
    user,
}) => {
    return (
        <div>
            <div id="integration-react" className="zoom-anim-dialog dialog">
                <div className="pop-title Box-divider--light-bottom-1 Flex-flex Flex-justifyContent--spaceBetween">
                    <button
                        className="int-back"
                        onClick={() => setActiveModal("main")}
                    >
                        <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="int-arrow"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </button>
                    <button
                        className="Modal__close-btn"
                        onClick={() => setShowModal(false)}
                    >
                        &times;
                    </button>
                </div>
                <div className="intbox">
                    <div className="intbox-align">
                        <h1 className="left">React component</h1>
                    </div>
                    <main className="notion">
                        <p className="notion-text">
                            We have an npm package that exposes our feedback
                            form as a React component. To use it, first install
                            the package:
                        </p>
                        <pre className="notion-code language-shell-session css-18cntup">
                            <code className="language-shell-session css-1s4whhm">
                                <span className="token output">
                                    npm install sloovi-feedback
                                </span>
                            </code>
                        </pre>
                        <p className="notion-text">
                            Then import the component in your React app and wrap
                            whatever button you want to trigger the widget with
                            it:
                        </p>
                        <pre className="notion-code language-jsx css-18cntup">
                            <code className="language-jsx css-0">
                                <span className="token keyword">import</span>{" "}
                                <span className="token punctuation">{"{"}</span>{" "}
                                SlooviFeedback{" "}
                                <span className="token punctuation">{"}"}</span>{" "}
                                <span className="token keyword">from</span>{" "}
                                <span className="token string">
                                    'sloovi-feedback'
                                </span>
                                {"\n"}
                                {"                                 "}
                                {"\n"}
                                <span className="token keyword">
                                    const
                                </span>{" "}
                                <span className="token function-variable function">
                                    App
                                </span>{" "}
                                <span className="token operator">=</span>{" "}
                                <span className="token punctuation">(</span>
                                <span className="token punctuation">
                                    )
                                </span>{" "}
                                <span className="token operator">=&gt;</span>{" "}
                                <span className="token punctuation">{"{"}</span>
                                {"\n"}
                                {"   "}
                                <span className="token comment">
                                    // Get your projectId from your dashboard at
                                    sloovi.feedback/app
                                </span>
                                {"\n"}
                                {"   "}
                                <span className="token keyword">
                                    return
                                </span>{" "}
                                <span className="token punctuation">(</span>
                                {"\n"}
                                {"      "}
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        <span className="token class-name">
                                            SlooviFeedback
                                        </span>
                                    </span>{" "}
                                    <span className="token attr-name">
                                        projectId
                                    </span>
                                    <span className="token attr-value">
                                        <span className="token punctuation attr-equals">
                                            =
                                        </span>
                                        <span className="token punctuation">
                                            "
                                        </span>
                                        {project}
                                        <span className="token punctuation">
                                            "
                                        </span>
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    {"\n"}
                                    {"         "}
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        <span className="token class-name">
                                            NavButton
                                        </span>
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    Send feedback
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        <span className="token class-name">
                                            NavButton
                                        </span>
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    {"\n"}
                                    {"      "}
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        <span className="token class-name">
                                            SlooviFeedback
                                        </span>
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                {"\n"}
                                {"   "}
                                <span className="token punctuation">)</span>
                                {"\n"}
                                <span className="token punctuation">{"}"}</span>
                            </code>
                        </pre>
                        <p className="notion-text">
                            To identify the submitting user, pass the email of
                            the currently authenticated user to the{" "}
                            <code className="notion-inline-code">userId</code>{" "}
                            prop:
                        </p>
                        <pre className="notion-code language-jsx css-18cntup">
                            <code className="language-jsx css-0">
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        <span className="token class-name">
                                            SlooviFeedback
                                        </span>
                                    </span>{" "}
                                    <span className="token attr-name">
                                        projectId
                                    </span>
                                    <span className="token attr-value">
                                        <span className="token punctuation attr-equals">
                                            =
                                        </span>
                                        <span className="token punctuation">
                                            "
                                        </span>
                                        {project}
                                        <span className="token punctuation">
                                            "
                                        </span>
                                    </span>{" "}
                                    <span className="token attr-name">
                                        clientId
                                    </span>
                                    <span className="token attr-value">
                                        <span className="token punctuation attr-equals">
                                            =
                                        </span>
                                        <span className="token punctuation">
                                            "
                                        </span>
                                        {client}
                                        <span className="token punctuation">
                                            "
                                        </span>
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    {"\n"}
                                    {"   "}
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        <span className="token class-name">
                                            NavButton
                                        </span>
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    Send feedback
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        <span className="token class-name">
                                            NavButton
                                        </span>
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">{"\n"}</span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        <span className="token class-name">
                                            SlooviFeedback
                                        </span>
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                            </code>
                        </pre>
                        <div className="notion-blank">&nbsp;</div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default ReactIntegration;
