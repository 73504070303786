import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale-subtle.css";
import { toast } from "react-toastify";

import Menu from "./Menu/Menu";
import styles from "./Sidebar.module.css";
import { getCompanyId, setCompanyId } from "../../utils/tokenUtilities";

const Sidebar = () => {
    const history = useHistory();

    const { company_accounts } = useSelector((state) => state.auth);
    const {
        user: { icon, name },
    } = useSelector((state) => state.auth);


   
    const [activeAccount, setActiveAccount] = useState();
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        if (company_accounts?.length > 0) {
            const active = company_accounts.find(
                (item) => item.company_id === getCompanyId()
            );
            setActiveAccount(active);
        }
    }, [company_accounts]);

    const changeAccountHandler = (comp_id, comp_name) => {
        if (comp_id !== activeAccount.company_id) {
            setCompanyId(comp_id);
            toast.success(`Switching to ${comp_name} account...`);
            history.go(0);
        }
    };

    return (
        <div className="atd-World-sideNavContainer">
            <div className="atd-SideNav-container Box-root Flex-flex Flex-direction--column">
                <div
                    style={{
                        marginBottom: "30px",
                        padding: "12px",
                    }}
                >
                    <Tippy
                        visible={showDropdown}
                        onClickOutside={() => setShowDropdown(false)}
                        offset={[0, 10]}
                        animation="scale-subtle"
                        theme="light-border"
                        placement="bottom-start"
                        interactive={true}
                        content={
                            <ul className={styles.org_dropdown_menu}>
                                {company_accounts?.map((item) => (
                                    <li
                                        key={item.company_id}
                                        onClick={() => {
                                            setShowDropdown(false);
                                            changeAccountHandler(
                                                item.company_id,
                                                item.company
                                            );
                                        }}
                                    >
                                        {item.company}
                                    </li>
                                ))}
                            </ul>
                        }
                    >
                        <div className={styles.org_dropdown}>
                            <button
                                className={styles.org_dropdown_btn}
                                onClick={() => setShowDropdown(!showDropdown)}
                            >
                                <span>{activeAccount?.company}</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </button>
                            <img
                                src={icon}
                                alt={name}
                                className={styles.org_dropdown_avatar}
                            />
                        </div>
                    </Tippy>

                    {/* Menu Items */}
                    <Menu />
                </div>
                {/* Wrapper div - image and menus */}
            </div>
        </div>
    );
};

export default Sidebar;
