import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale-subtle.css";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

const TableItem = ({ item, deleteFeedbackAsync, markArchiveAsync }) => {
    const dispatch = useDispatch();
    const [openAccordion, setOpenAccordion] = useState(false);
    const [itemClass, setItemClass] = useState("");

    const removePropagation = (e) => {
        e.stopPropagation();
    };

    // Classes for Type Badges
    const setTypeClasses = (type) => {
        switch (type) {
            case "idea":
                return "ideas";
            case "issue":
                return "issu";
            case "other":
                return "yet";
            default:
                return "yet";
        }
    };

    // Archive Handler
    const sendToArchiveHandler = async (e, id) => {
        e.stopPropagation();
        e.target.classList.add("submenu-loading");
        await Promise.all([dispatch(markArchiveAsync(id))]);
        e.target.classList.remove("submenu-loading");
    };

    // Delete Feedback Handler
    const deleteFeedbackHandler = async (e, id) => {
        e.stopPropagation();
        e.target.classList.add("submenu-loading");
        await Promise.all([dispatch(deleteFeedbackAsync(id))]);
        e.target.classList.remove("submenu-loading");
    };

    const submenu = (
        <ul className="submenu">
            {!item.is_archived && (
                <li onClick={(e) => sendToArchiveHandler(e, item.id)}>
                    <span className="loading-icon">
                        <i className="fa fa-spinner fa-pulse"></i>
                        <span className="sr-only">Loading...</span>
                    </span>
                    Archive
                </li>
            )}
            {item.user_email && (
                <li onClick={removePropagation}>
                    <a
                        target="_blank"
                        href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Re:+Your+feedback&to=demo@alltimedesign.com&body=%3E+test+issue+by+subi%0D%0A%0D%0A"
                    >
                        Reply with Mail
                    </a>
                </li>
            )}
            <li onClick={(e) => deleteFeedbackHandler(e, item.id)}>
                <span className="red-txt">
                    <span className="loading-icon">
                        <i className="fa fa-spinner fa-pulse"></i>
                        <span className="sr-only">Loading...</span>
                    </span>
                    Delete Feedback
                </span>
            </li>
        </ul>
    );

    return (
        <li className={`${openAccordion ? "open" : "closed"} ${itemClass}`}>
            <header onClick={() => setOpenAccordion(!openAccordion)}>
                <table className="table mb-0 wt-100 sm-ht fulltable">
                    <tbody>
                        <tr>
                            <td style={{ width: "40%" }}>
                                <div
                                    className={`title2 pl0 ${
                                        item.is_blured ? "blur" : ""
                                    }`}
                                >
                                    {item.feedback_title}
                                </div>
                            </td>
                            <td style={{ width: "30%" }}>
                                <div
                                    className={`blu-txt cont2 ${
                                        item.is_blured ? "blur" : ""
                                    }`}
                                >
                                    {item.user_email}
                                </div>
                            </td>
                            <td style={{ width: "12%" }}>
                                <div
                                    className={setTypeClasses(
                                        item.feedback_type
                                    )}
                                >
                                    {item.feedback_type}
                                </div>
                            </td>
                            <td style={{ width: "12%" }} className="cont2">
                                {moment(item.created).format("DD MMM YYYY")}
                            </td>
                            <td className="dropdown" style={{ width: "4%" }}>
                                <Tippy
                                    trigger="click"
                                    offset={[0, 8]}
                                    animation="scale-subtle"
                                    theme="light-border"
                                    placement="bottom-end"
                                    interactive={true}
                                    content={submenu}
                                >
                                    <div
                                        className="dots btn"
                                        onClick={removePropagation}
                                    >
                                        <svg
                                            aria-hidden="true"
                                            className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--more-svg Button-icon-svg Icon-color-svg Icon-color--gray600-svg"
                                            height={12}
                                            width={12}
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                                fillRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </Tippy>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </header>
            <section className="content">
                <p className={`cont3 ${item.is_blured ? "blur" : ""}`}>
                    {ReactHtmlParser(item.feedback_desc)}
                </p>
            </section>
        </li>
    );
};

export default TableItem;
