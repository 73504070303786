import React from "react";
import { Sidebar, Navbar } from "../components";

const Layout = ({ children }) => {
    return (
        <div className="atd-World-root">
            <div className="atd-World-wrapper Box-root Flex-flex Flex-direction--column">
                {/* atd-World-mainWrapper - The one with the scrollbar (for infinite scrolling) */}
                <div className="atd-World-scrollWrapper">
                    {/* Main */}
                    <div className="atd-World-mainWrapper">
                        {/* Main Content Area */}
                        <div className="atd-World-mainPane" tabIndex="-1">
                            <div className="atd-World-contentPane" role="main">
                                <div className="db-CardlessUIPageWrapper Box-root Padding-horizontal--20">
                                    {children}
                                </div>
                                {/* CardlessUIPageWrapper */}
                            </div>
                            {/* atd-World-contentPane */}
                        </div>
                        {/* atd-World-mainPane */}

                        <Navbar />
                    </div>
                    {/* atd-World-mainWrapper */}

                    {/* Sidebar  */}
                    <Sidebar />
                </div>
            </div>
        </div>
    );
};

export default Layout;
