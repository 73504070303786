import React from "react";
import TableItem from "./TableItem";

const Table = ({ teamMembers, onChangeUserRole, onDeleteUser }) => {
    return (
        <div>
            <table className="table mb-0 wt-100 mgt1" id="teams">
                <thead className="stik">
                    <tr>
                        <th scope="col" className="border-0 tb-title pl5">
                            NAME
                        </th>
                        <th scope="col" className="border-0 tb-title pl5">
                            EMAIL
                        </th>
                        <th scope="col" className="border-0 tb-title pl5">
                            ROLE
                        </th>
                        <th scope="col" className="border-0 tb-title pl5">
                            LAST ACTIVITY
                        </th>
                        <th scope="col" className="border-0 tb-title pl5">
                            STATUS
                        </th>
                        <th
                            scope="col"
                            className="border-0"
                            style={{ width: "4%" }}
                        />
                    </tr>
                </thead>
                <tbody>
                    {teamMembers.length > 0 ? (
                        teamMembers.map((member) => (
                            <TableItem
                                key={member.id}
                                member={member}
                                onChangeUserRole={onChangeUserRole}
                                onDeleteUser={onDeleteUser}
                            />
                        ))
                    ) : (
                        <tr>
                            <td
                                colSpan="6"
                                style={{ borderBottom: "0px", color: "#888" }}
                            >
                                <p className="Padding-vertical--16 Text-align--center">
                                    Nothing to show!
                                </p>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
