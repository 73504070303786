import React from 'react';

import FeedbackAction from './FeedbackAction';

const FeedbackModal = ({ loading, children, onCancel, onSend }) => {
  return (
    <div className="sloovi-FeedbackModal">
      <div className="sloovi-FeedbackForm">
        <div className="sloovi-FeedbackForm-step sloovi-FeedbackForm-step--form">
          {children}
          <FeedbackAction
            loading={loading}
            onCancel={onCancel}
            onSend={onSend}
          />
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
