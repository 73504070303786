import React, { useState } from "react";
import "react-dates/initialize";

// import CustomDate from "../DayRangePicker/CustomDate";

// TippyJs
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale-subtle.css";

const DateRangeFilter = () => {
    const [showDate, setShowDate] = useState(false);

    const onChangeCustomDate = (fromDate, toDate) => {
        console.log("start date : ", fromDate);
        console.log("end date : ", toDate);
    };

    return (
        <>
            <div className="Box-root Flex-flex" id="cale">
                <div className="Box-root Flex-flex dropdown">
                    <button
                        className="db-InlineFilterButton btn"
                        type="button"
                        onClick={() => setShowDate(!showDate)}
                    >
                        <div
                            aria-hidden="true"
                            className="SVGInline SVGInline--cleaned SVG Icon Icon--calendar db-InlineDateRangeFilter-calendarIcon Icon-color Icon-color--gray600 Box-root Flex-flex"
                        >
                            <svg
                                aria-hidden="true"
                                className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--calendar-svg db-InlineDateRangeFilter-calendarIcon-svg Icon-color-svg Icon-color--gray600-svg"
                                height={14}
                                width={14}
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 8h16v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm13-6h1a2 2 0 0 1 2 2v2H0V4a2 2 0 0 1 2-2h1v1a1 1 0 1 0 2 0V2h6v1a1 1 0 0 0 2 0zM4 0a1 1 0 0 1 1 1v1H3V1a1 1 0 0 1 1-1zm8 0a1 1 0 0 1 1 1v1h-2V1a1 1 0 0 1 1-1z"
                                    fillRule="evenodd"
                                />
                            </svg>
                        </div>
                    </button>

                    {/* <Tippy
                        visible={false}
                        onClickOutside={() => {
                            setShowDate(false);
                        }}
                        offset={[30, 8]}
                        animation="scale-subtle"
                        theme="light-border"
                        placement="bottom"
                        interactive={true}
                        content={
                            <div>
                                <div className="DateRangePicker">
                                    <CustomDate
                                        onSave={({ start, end }) => {
                                            if (start && end) {
                                                onChangeCustomDate(
                                                    start._d,
                                                    end._d
                                                );
                                                setShowDate(false);
                                            }
                                        }}
                                        onCancel={() => {
                                            // onChangeDateRange("today");
                                            setShowDate(false);
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    >
                        <button
                            className="db-InlineFilterButton btn"
                            type="button"
                            onClick={() => setShowDate(!showDate)}
                        >
                            <div
                                aria-hidden="true"
                                className="SVGInline SVGInline--cleaned SVG Icon Icon--calendar db-InlineDateRangeFilter-calendarIcon Icon-color Icon-color--gray600 Box-root Flex-flex"
                            >
                                <svg
                                    aria-hidden="true"
                                    className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--calendar-svg db-InlineDateRangeFilter-calendarIcon-svg Icon-color-svg Icon-color--gray600-svg"
                                    height={14}
                                    width={14}
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 8h16v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm13-6h1a2 2 0 0 1 2 2v2H0V4a2 2 0 0 1 2-2h1v1a1 1 0 1 0 2 0V2h6v1a1 1 0 0 0 2 0zM4 0a1 1 0 0 1 1 1v1H3V1a1 1 0 0 1 1-1zm8 0a1 1 0 0 1 1 1v1h-2V1a1 1 0 0 1 1-1z"
                                        fillRule="evenodd"
                                    />
                                </svg>
                            </div>
                        </button>
                    </Tippy> */}
                </div>
            </div>
        </>
    );
};

export default DateRangeFilter;
