import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	getCompanyAccDataAsync,
	getUserDetailsAsync,
	setCreadentials,
} from "./redux/authSlice";
import { getCompanyId, getToken } from "./utils/tokenUtilities";

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

import FeedbackAll from "./pages/feedback/FeedbackAll";
import FeedbackIdea from "./pages/feedback/FeedbackIdea";
import FeedbackIssue from "./pages/feedback/FeedbackIssue";
import FeedbackOther from "./pages/feedback/FeedbackOther";
import AccountsAndSettings from "./pages/AccountsAndSettings/AccountsAndSettings";
import TeamsAndPermissions from "./pages/TeamsAndPermissions/TeamsAndPermissions";
import FeedbackArchive from "./pages/feedback/FeedbackArchive";
import Login from "./pages/Login/Login";

import Widget from "./pages/Widget";
import Accept from "./pages/Accept/Accept";

const App = () => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.auth);

	useEffect(() => {
		if (!token) {
			if (getToken()) {
				dispatch(
					setCreadentials({
						token: getToken(),
						company_id: getCompanyId(),
					})
				);
				dispatch(
					getCompanyAccDataAsync({
						token: getToken(),
						company_id: getCompanyId(),
					})
				);
				dispatch(
					getUserDetailsAsync({
						token: getToken(),
						company_id: getCompanyId(),
					})
				);
			}
		}
	}, [dispatch, token]);

	return (
		<>
			<Router>
				<Switch>
					<Redirect exact from='/' to='/login' />
					<Redirect exact from='/feedback' to='/feedback/all' />
					<Route path='/accept'>
						<Accept />
					</Route>
					<Route path='/login'>
						{token ? <Redirect to='/feedback/all' /> : <Login />}
					</Route>
					<PrivateRoute path='/feedback/all'>
						<FeedbackAll />
					</PrivateRoute>
					<PrivateRoute path='/feedback/issue'>
						<FeedbackIssue />
					</PrivateRoute>
					<PrivateRoute path='/feedback/idea'>
						<FeedbackIdea />
					</PrivateRoute>
					<PrivateRoute path='/feedback/other'>
						<FeedbackOther />
					</PrivateRoute>
					<PrivateRoute path='/feedback/archive'>
						<FeedbackArchive />
					</PrivateRoute>
					<PrivateRoute path='/plans'>
						<AccountsAndSettings />
					</PrivateRoute>
					<PrivateRoute path='/teams-and-permissions'>
						<TeamsAndPermissions />
					</PrivateRoute>
					<Route path='/widget'>
						<Widget />
					</Route>
					<Route path='*'>
						<h4 className='Text-align--center Padding-vertical--24'>
							ERROR 404
						</h4>
						<p className='Text-align--center'>Page Not Found!</p>
					</Route>
				</Switch>

				{/* Toast */}
				<ToastContainer
					theme='dark'
					position='bottom-center'
					autoClose={5000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Router>
		</>
	);
};

export default App;
