import React from "react";
import { NavLink } from "react-router-dom";

const Menu = () => {
    return (
        <>
            <NavLink className="mnu" to="/feedback">
                <div className="Box-root Flex-flex Flex-alignItems--center mgt3">
                    <div className="Box-root Margin-right--12 Padding-all--2 Flex-flex Flex-alignItems--center">
                        <div className="SVGInline SVGInline--cleaned SVG db-SideNav-icon db-SideNav-icon--connect db-SideNav-icon--highlighted Box-root Flex-flex">
                            <svg
                                className="SVGInline-svg SVGInline--cleaned-svg SVG-svg db-SideNav-icon-svg db-SideNav-icon--connect-svg db-SideNav-icon--highlighted-svg"
                                style={{
                                    width: "16px",
                                    height: "16px",
                                }}
                                height="16"
                                viewBox="0 0 16 16"
                                width="16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g fill="none">
                                    <path
                                        d="M8.002 16.004A8.002 8.002 0 118.002 0c.455 0 .9.047 1.334.12V5h1.666v1.668h4.882c.073.435.12.88.12 1.334a8.002 8.002 0 01-8.002 8.002zm1.992-6.01H6.348a.34.34 0 01-.34-.34v-3.64h-2.44a.338.338 0 00-.24.1l-2.23 2.23a6.905 6.905 0 006.57 6.563l2.227-2.228c.063-.063.1-.15.1-.24V9.992z"
                                        fill="#A3ACB9"
                                        className="db-SideNav-iconFill--secondary"
                                    ></path>
                                    <path
                                        d="M15.886 6.686L12.68 9.894a.338.338 0 01-.24.1H9.994V6.352a.338.338 0 00-.338-.338H6.009V3.559a.34.34 0 01.1-.24l3.2-3.203a7.993 7.993 0 016.577 6.57z"
                                        fill="#4F566B"
                                        className="db-SideNav-iconFill--primary"
                                    ></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                    Feedback
                </div>
            </NavLink>

            <div className="db-SideNav--expandable" id="reportsm">
                <ul>
                    <li>
                        <NavLink className="db-SideNav-link" to="/feedback/all">
                            <div className="db-SideNav-itemContent NavItem Box-root Padding-horizontal--4 Padding-vertical--2">
                                <div className="Box-root Padding-left--32 Flex-flex Flex-alignItems--flexStart">
                                    <span className="Text-color--default Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                        <span className="repsm">All</span>
                                    </span>
                                </div>
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className="db-SideNav-link"
                            to="/feedback/issue"
                        >
                            <div className="db-SideNav-itemContent NavItem Box-root Padding-horizontal--4 Padding-vertical--2">
                                <div className="Box-root Padding-left--32 Flex-flex Flex-alignItems--flexStart">
                                    <span className="Text-color--default Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                        <span className="repsm">Issue</span>
                                    </span>
                                </div>
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className="db-SideNav-link"
                            to="/feedback/idea"
                        >
                            <div className="db-SideNav-itemContent NavItem Box-root Padding-horizontal--4 Padding-vertical--2">
                                <div className="Box-root Padding-left--32 Flex-flex Flex-alignItems--flexStart">
                                    <span className="Text-color--default Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                        <span className="repsm">Idea</span>
                                    </span>
                                </div>
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className="db-SideNav-link"
                            to="/feedback/other"
                        >
                            <div className="db-SideNav-itemContent NavItem Box-root Padding-horizontal--4 Padding-vertical--2">
                                <div className="Box-root Padding-left--32 Flex-flex Flex-alignItems--flexStart">
                                    <span className="Text-color--default Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                        <span className="repsm">Other</span>
                                    </span>
                                </div>
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className="db-SideNav-link"
                            to="/feedback/archive"
                        >
                            <div className="db-SideNav-itemContent NavItem Box-root Padding-horizontal--4 Padding-vertical--2">
                                <div className="Box-root Padding-left--32 Flex-flex Flex-alignItems--flexStart">
                                    <span className="Text-color--default Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
                                        <span className="repsm">Archive</span>
                                    </span>
                                </div>
                            </div>
                        </NavLink>
                    </li>
                </ul>
            </div>

            {/* Account Settings */}
            {/* <NavLink to="/account-settings" className="mnu">
                <div className="Box-root Flex-flex Flex-alignItems--flexStart">
                    <div className="Box-root Margin-right--12 Padding-all--2 Flex-flex Flex-alignItems--center">
                        <div className="SVGInline SVGInline--cleaned SVG db-SideNav-icon db-SideNav-icon--payments Box-root Flex-flex">
                            <div className="SVGInline SVGInline--cleaned SVG db-SideNav-icon db-SideNav-icon--settings db-SideNav-icon--highlighted Box-root Flex-flex">
                                <svg
                                    className="SVGInline-svg SVGInline--cleaned-svg SVG-svg db-SideNav-icon-svg db-SideNav-icon--settings-svg db-SideNav-icon--highlighted-svg"
                                    style={{
                                        width: "16px",
                                        height: "16px",
                                    }}
                                    height="16"
                                    viewBox="0 0 16 16"
                                    width="16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.197 7.017c-.23 0-1.607 0-2.18-.695 0-.115-.115-.115-.115-.231-.115-.81.918-1.851 1.032-1.967.23-.231.23-.579.115-.694-.115-.116-.574-.579-.688-.81-.115-.232-.46-.116-.689.116-.23.23-1.147 1.157-1.95 1.04-.115 0-.115-.115-.23-.115-.689-.463-.689-1.851-.689-2.082 0-.348-.114-.579-.344-.579H7.311c-.229 0-.344.231-.344.579 0 .23 0 1.62-.688 2.198-.115 0-.115.116-.23.116-.803 0-1.721-.926-1.95-1.157-.23-.232-.574-.232-.69-.116-.114.231-.573.694-.802.81-.115.115-.115.463.114.694.23.231 1.148 1.157 1.033 1.967 0 .116-.115.116-.115.231-.459.695-1.836.695-2.065.695-.344 0-.574.115-.574.347v1.041c0 .231.23.463.574.463.23 0 1.606 0 2.18.694 0 .116.115.116.115.231.115.81-.918 1.852-1.033 1.967-.23.232-.23.579-.115.695.115.115.574.578.689.81.115.115.459.115.688-.116.23-.232 1.148-1.157 1.951-1.042.115 0 .115.116.23.116.688.579.688 1.851.688 2.198 0 .232.23.579.46.579h1.032c.23 0 .459-.231.459-.579 0-.23 0-1.62.689-2.198.114 0 .114-.116.23-.116.802-.115 1.835.926 1.95 1.042.23.231.574.231.688.115l.804-.81c.114-.115.114-.462-.115-.694-.23-.231-1.148-1.157-1.033-1.967 0-.115.115-.115.115-.231.574-.694 1.836-.694 2.18-.694.23 0 .574-.232.574-.463V7.364c-.23-.232-.459-.347-.803-.347zm-6.312 3.47c-1.377 0-2.524-1.156-2.524-2.545 0-1.388 1.147-2.545 2.524-2.545s2.525 1.157 2.525 2.545c0 1.389-1.148 2.546-2.525 2.546z"
                                        fill="#4F566B"
                                        className="db-SideNav-iconFill--primary"
                                        fillRule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    Account Settings
                </div>
            </NavLink>

            <NavLink to="/teams-and-permissions" className="mnu">
                <div className="Box-root Flex-flex Flex-alignItems--flexStart mgt1">
                    <div className="Box-root Margin-right--12 Padding-all--2 Flex-flex Flex-alignItems--center">
                        <div className="SVGInline SVGInline--cleaned SVG db-SideNav-icon db-SideNav-icon--payments Box-root Flex-flex">
                            <svg
                                className="SVGInline-svg SVGInline--cleaned-svg SVG-svg db-SideNav-icon-svg db-SideNav-icon--customers-svg"
                                style={{
                                    width: "16px",
                                    height: "16px",
                                }}
                                height="16"
                                width="16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g fill="none">
                                    <path
                                        d="M13.445 13.861C12.413 12.885 10.362 12.22 8 12.22s-4.413.665-5.445 1.641a8 8 0 1110.89 0zM8 9.231a3.077 3.077 0 100-6.154 3.077 3.077 0 000 6.154z"
                                        fill="#4f566b"
                                        className="db-SideNav-iconFill--primary"
                                    ></path>
                                    <path
                                        d="M13.944 13.354A7.98 7.98 0 018 16a7.98 7.98 0 01-5.944-2.646C2.76 12.043 5.154 11.077 8 11.077s5.24.966 5.944 2.277z"
                                        fill="#4f566b"
                                        className="db-SideNav-iconFill--secondary"
                                    ></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                    Teams & Permissions
                </div>
            </NavLink> */}
        </>
    );
};

export default Menu;
