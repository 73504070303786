import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";

const initialState = {
  products: [],
  isLoading: false,
  erroMsg: "",
};

export const fetchSwictIconContent = createAsyncThunk(
  "switchProductIcons/fetchSwictIconContent",
  async (_, thunkAPI) => {
    try {
      const response = axiosInstance.getSwictIconContent();
      console.log(response);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue("error");
    }
  }
);

const getSwictIconContent = createSlice({
  name: "switchProductIcons",
  initialState,
  reducers: () => {},
  extraReducers: {
    [fetchSwictIconContent.pending]: (state, action) => {
      state.isLoading = true;
      state.erroMsg = "";
      state.products = [];
    },
    [fetchSwictIconContent.fulfilled]: (state, action) => {
      state.products = action.payload;
      state.erroMsg = "";
      state.isLoading = false;
    },
    [fetchSwictIconContent.rejected]: (state, action) => {
      state.isLoading = false;
      state.erroMsg = "";
      state.products = [];
    },
  },
});

export default getSwictIconContent.reducer;
