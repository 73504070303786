import React from 'react';

const FeedbackBox = ({
  setFeedbackIssue,
  setFeedbackIdea,
  setFeedbackOther,
}) => {
  return (
    <div className="feedbackbox">
      <div className="sloovi-FeedbackModal">
        <div className="sloovi-FeedbackForm">
          <div className="sloovi-FeedbackForm-step sloovi-FeedbackForm-step--form center">
            <div className="rep-title mgt1">What's on your mind?</div>
            <div className="mgt1">
              <div
                className="Card-root Card--radius--all Card--shadow--small Box-root Box-background--white issue ccss40"
                onClick={setFeedbackIssue}
              >
                <div className="Box-root Box-background--offset Padding-all--8 fbtn">
                  <div
                    aria-hidden="true"
                    className="SVGInline SVGInline--cleaned SVG Icon Icon--warning Icon-color Icon-color--gray Box-root"
                  >
                    <svg
                      aria-hidden="true"
                      className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--warning-svg Icon-color-svg Icon-color--gray-svg"
                      height={24}
                      width={24}
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.115 1.308l5.635 11.269A2.365 2.365 0 0 1 13.634 16H2.365A2.365 2.365 0 0 1 .25 12.577L5.884 1.308a2.365 2.365 0 0 1 4.231 0zM8 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z"
                        fillRule="evenodd"
                        fill="#5469d4"
                      />
                    </svg>
                  </div>
                  Issue
                </div>
              </div>
              <div
                className="Card-root Card--radius--all Card--shadow--small Box-root Box-background--white idea ccss40"
                onClick={setFeedbackIdea}
              >
                <div className="Box-root Box-background--offset Padding-all--8 fbtn">
                  <div
                    aria-hidden="true"
                    className="SVGInline SVGInline--cleaned SVG Icon Icon--warning Icon-color Icon-color--gray Box-root"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      fill="currentColor"
                      className="bi bi-lightbulb-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"
                        fill="#5469d4"
                      />
                    </svg>
                  </div>
                  Idea
                </div>
              </div>
              <div
                className="Card-root Card--radius--all Card--shadow--small Box-root Box-background--white other ccss40"
                onClick={setFeedbackOther}
              >
                <div className="Box-root Box-background--offset Padding-all--8 fbtn">
                  <div
                    aria-hidden="true"
                    className="SVGInline SVGInline--cleaned SVG Icon Icon--warning Icon-color Icon-color--gray Box-root"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true"
                      focusable="false"
                      height={24}
                      width={24}
                      className="tmrot2"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M10.001 7.8a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 10 7.8zm-7 0a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 3 7.8zm14 0a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 17 7.8z"
                        fill="#5469d4"
                      />
                    </svg>
                  </div>
                  Other
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackBox;
