import React from "react";

const HTMLIntegration = ({
    setActiveModal,
    setShowModal,
    project,
    client,
    user,
}) => {
    return (
        <>
            <div id="integration-html" className="zoom-anim-dialog dialog">
                <div className="pop-title Box-divider--light-bottom-1 Flex-flex Flex-justifyContent--spaceBetween">
                    <button
                        className="int-back"
                        onClick={() => setActiveModal("main")}
                    >
                        <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="int-arrow"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </button>
                    <button
                        className="Modal__close-btn"
                        onClick={() => setShowModal(false)}
                    >
                        &times;
                    </button>
                </div>
                <div className="intbox">
                    <div className="intbox-align">
                        <h1 className="left">Usage on any website</h1>
                    </div>
                    <main className="notion">
                        <p className="notion-text">
                            To embed the feedback widget on your website, first
                            add the script tag with your project id to the HTML:
                        </p>
                        <pre className="notion-code language-jsx css-18cntup">
                            <code className="language-jsx css-0">
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        script
                                    </span>{" "}
                                    <span className="token attr-name">
                                        defer
                                    </span>{" "}
                                    <span className="token attr-name">src</span>
                                    <span className="token attr-value">
                                        <span className="token punctuation attr-equals">
                                            =
                                        </span>
                                        <span className="token punctuation">
                                            "
                                        </span>
                                        {`https://feedback.sloovi.com/sloovi--feedback.js?project_id=${project}&client_id=${client}&user_id=${user}`}
                                        <span className="token punctuation">
                                            "
                                        </span>
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text" />
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        script
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                            </code>
                        </pre>
                        <p className="notion-text">
                            Then, add the{" "}
                            <code className="notion-inline-code">
                                data-sloovi-feedback
                            </code>{" "}
                            attribute to any HTML element that should trigger
                            the widget on click.
                        </p>
                        <pre className="notion-code language-jsx css-18cntup">
                            <code className="language-jsx css-0">
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        button
                                    </span>{" "}
                                    <span className="token attr-name">
                                        data-sloovi-feedback
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    {"\n"}Feedback{"\n"}
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        button
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                            </code>
                        </pre>
                        <p className="notion-text">
                            If you want to identify which user submitted
                            feedback, you can optionally add the{" "}
                            <code className="notion-inline-code">
                                data-sloovi-feedback-userid
                            </code>
                            attribute to the button and pass in the currently
                            authenticated user's email or id.
                        </p>
                        <pre className="notion-code language-jsx css-18cntup">
                            <code className="language-jsx css-0">
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;
                                        </span>
                                        button
                                    </span>{" "}
                                    {"\n"}
                                    <span className="token attr-name">
                                        data-sloovi-feedback
                                    </span>
                                    {"\n"}
                                    <span className="token attr-name">
                                        data-sloovi-feedback-userid
                                    </span>
                                    <span className="token attr-value">
                                        <span className="token punctuation attr-equals">
                                            =
                                        </span>
                                        <span className="token punctuation">
                                            "
                                        </span>
                                        ...
                                        <span className="token punctuation">
                                            "
                                        </span>
                                    </span>
                                    {"\n"}
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                                <span className="token plain-text">
                                    {"\n"}Feedback{"\n"}
                                </span>
                                <span className="token tag">
                                    <span className="token tag">
                                        <span className="token punctuation">
                                            &lt;/
                                        </span>
                                        button
                                    </span>
                                    <span className="token punctuation">
                                        &gt;
                                    </span>
                                </span>
                            </code>
                        </pre>
                    </main>
                </div>
            </div>
        </>
    );
};

export default HTMLIntegration;
