import React, { useContext } from 'react';

import { FeedbackContext } from '../Feedback/Feedback';

import classes from './SendMoreFeedbackButton.module.css';

const SendMoreFeedbackButton = () => {
  const { onSendMoreFeedback } = useContext(FeedbackContext);

  return (
    <button className={classes.btn1} onClick={onSendMoreFeedback}>
      Submit more feedback
    </button>
  );
};

export default SendMoreFeedbackButton;
