import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";

// ==== API Details ====
// https://stage.api.sloovi.com/feedback?sort=desc&page=1
// company_id and token everytime required
// can be found in axiosInstance
// =====================

// Get all the feedbacks
export const getFeedbacksAsync = createAsyncThunk(
    "feedbacks/getFeedbacksAsync",
    async (payload) => {
        // console.log(payload);
        const res = await axiosInstance.getFeedbacks(payload.params);

        // console.log(res);
        // console.log(res.data.results.data);
        if (res.data.code === 200) {
            return {
                feedbacks: res.data.results.data,
                hasMore: res.data.results.has_more,
            };
        } else {
            throw new Error("Server Error!");
        }
    }
);

// Delete a Single Feedback
export const deleteFeedbackAsync = createAsyncThunk(
    "feedbacks/deleteFeedbackAsync",
    async (id) => {
        // console.log(id);
        await axiosInstance.deleteFeedback(id);

        return id;
    }
);

// Mark a feedback as Archived
export const markArchiveAsync = createAsyncThunk(
    "feedbacks/markArchiveAsync",
    async (id) => {
        await axiosInstance.markArchive(id);

        return id;
    }
);

export const feedbackSlice = createSlice({
    name: "feedbacks",
    initialState: {
        loading: false,
        hasErrors: false,
        feedbacks: [],
        hasMore: false,
    },
    reducers: {
        emptyFeedback: (state, action) => {
            state.feedbacks = [];
        },
    },
    extraReducers: {
        [getFeedbacksAsync.pending]: (state, action) => {
            state.loading = true;
        },
        [getFeedbacksAsync.fulfilled]: (state, action) => {
            // console.log(action.payload);
            state.feedbacks = [...state.feedbacks, ...action.payload.feedbacks];
            state.hasMore = action.payload.hasMore;
            state.loading = false;
            state.hasErrors = false;
        },
        [getFeedbacksAsync.rejected]: (state, action) => {
            state.loading = false;
            state.hasErrors = true;
        },
        [deleteFeedbackAsync.fulfilled]: (state, action) => {
            state.feedbacks = state.feedbacks.filter(
                (item) => item.id !== action.payload
            );
        },
        [markArchiveAsync.fulfilled]: (state, action) => {
            state.feedbacks = state.feedbacks.filter(
                (item) => item.id !== action.payload
            );
        },
    },
});

export const { emptyFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
