import React from "react";
import DateFilter from "./DateFilter";
import DateRangeFilter from "./DateRangeFilter";

const FilterBar = ({ dateFilterHandler }) => {
    const clearAllHandler = () => {
        dateFilterHandler("all_time");
    };

    return (
        <>
            <div
                className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--spaceBetween Flex-wrap--nowrap"
                id="filter"
                style={{
                    paddingRight: "4px",
                    paddingLeft: "1px",
                    borderBottom: "1px solid #E0E1E4",
                }}
            >
                <div
                    className="db-InlineFilterForm-bottom Box-root"
                    style={{ pointerEvents: "none" }}
                >
                    <div
                        className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
                        style={{ marginLeft: "-8px", marginTop: "-8px" }}
                    >
                        <div
                            className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8"
                            style={{ pointerEvents: "auto" }}
                        >
                            <div className="db-InlineDateRangeFilter-group">
                                <DateFilter
                                    dateFilterHandler={dateFilterHandler}
                                />
                                <span className="db-InlineDateRangeFilter-separator"></span>
                                <DateRangeFilter />
                            </div>
                            {/* db-InlineDateRangeFilter-group */}
                        </div>
                    </div>
                </div>
                {/* db-InlineFilterForm-bottom */}
                <button
                    className="Filter-ClearAll__btn"
                    onClick={clearAllHandler}
                >
                    Clear all
                </button>
                {/* <div
                    className="PressableCore PressableCore--cursor--pointer PressableCore--height--small PressableCore--radius--all PressableCore--width PressableCore--width--auto PressableButton Button Button--color--white Box-root Flex-inlineFlex"
                    style={{
                        backgroundColor: "rgb(255, 255, 255)",
                        boxShadow:
                            "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px",
                    }}
                >
                    <div className="PressableCore-base Box-root">
                        <button
                            data-db-analytics-name="inline_filter_form_clear_all_button"
                            className="UnstyledLink Button-element PressableContext Padding-horizontal--8 Padding-vertical--4 PressableContext--cursor--pointer PressableContext--display--inlineFlex PressableContext--fontLineHeight--16 PressableContext--fontSize--13 PressableContext--fontWeight--medium PressableContext--height PressableContext--height--small PressableContext--radius--all PressableContext--width PressableContext--width--auto"
                            type="button"
                            style={{ color: "rgb(60, 66, 87)" }}
                        >
                            <div
                                className="Button-align Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row"
                                style={{ position: "relative" }}
                            >
                                <div
                                    className="TextAligner Box-root"
                                    style={{
                                        lineHeight: 16,
                                        fontSize: 13,
                                        flex: "0 0 auto",
                                    }}
                                ></div>
                                <div
                                    className="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row Flex-justifyContent--center"
                                    style={{
                                        width: "100%",
                                        lineHeight: 0,
                                        flex: "1 1 auto",
                                    }}
                                >
                                    <span
                                        className="Button-label Text-color--default Text-fontSize--13 Text-fontWeight--medium Text-lineHeight--16 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--block"
                                        style={{ marginTop: "-1px" }}
                                    >
                                        Clear all
                                    </span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div> */}
                {/* PressableCore PressableCore--cursor--pointer */}
            </div>
        </>
    );
};

export default FilterBar;
