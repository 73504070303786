import { configureStore } from "@reduxjs/toolkit";
import feedbackReducer from "./feedbackSlice";
import issueReducer from "./issueSlice";
import ideaReducer from "./ideaSlice";
import otherReducer from "./otherSlice";
import archiveReducer from "./archiveSlice";
import authReducer from "./authSlice";
import teamReducer from "./teamSlice";
import getSwictIconContent from "./switchIconsSlice"

export default configureStore({
    reducer: {
        auth: authReducer,
        feedbacks: feedbackReducer,
        issues: issueReducer,
        ideas: ideaReducer,
        others: otherReducer,
        archives: archiveReducer,
        teams: teamReducer,
        switchProductIcons:getSwictIconContent
    },
});
