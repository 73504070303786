import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale-subtle.css";

const DateFilter = ({ dateFilterHandler }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <>
            <div className="Box-root Flex-flex">
                <div className="Box-root Flex-flex dropdown">
                    <Tippy
                        visible={showDropdown}
                        onClickOutside={() => setShowDropdown(false)}
                        offset={[20, 8]}
                        animation="scale-subtle"
                        theme="light-border"
                        placement="bottom"
                        interactive={true}
                        content={
                            <ul className="submenu">
                                <li
                                    onClick={() => {
                                        dateFilterHandler("last_hour");
                                        setShowDropdown(false);
                                    }}
                                >
                                    Last hour
                                </li>
                                <li
                                    onClick={() => {
                                        dateFilterHandler("last_12_hours");
                                        setShowDropdown(false);
                                    }}
                                >
                                    Last 12 hours
                                </li>
                                <li
                                    onClick={() => {
                                        dateFilterHandler("today");
                                        setShowDropdown(false);
                                    }}
                                >
                                    Today
                                </li>
                                <li
                                    onClick={() => {
                                        dateFilterHandler("last_week");
                                        setShowDropdown(false);
                                    }}
                                >
                                    Last 7 days
                                </li>
                                <li
                                    onClick={() => {
                                        dateFilterHandler("last_14_days");
                                        setShowDropdown(false);
                                    }}
                                >
                                    Last 14 days
                                </li>
                                <li
                                    onClick={() => {
                                        dateFilterHandler("last_30_days");
                                        setShowDropdown(false);
                                    }}
                                >
                                    Last 30 days
                                </li>
                                <li
                                    onClick={() => {
                                        dateFilterHandler("all_time");
                                        setShowDropdown(false);
                                    }}
                                >
                                    <span className="Text-color--blue">
                                        Clear
                                    </span>
                                </li>
                            </ul>
                        }
                    >
                        <button
                            onClick={() => setShowDropdown(!showDropdown)}
                            aria-label="Date"
                            className="db-InlineFilterButton btn"
                            type="button"
                        >
                            Date
                        </button>
                    </Tippy>
                </div>
            </div>
        </>
    );
};

export default DateFilter;
