import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";

// ==== API Details ====
// https://stage.api.sloovi.com/feedback?sort=desc&page=1&filter_type=issue
// company_id and token everytime required
// can be found in axiosInstance
// =====================

export const getIssuesAsync = createAsyncThunk(
    "issues/getIssuesAsync",
    async (payload) => {
        // console.log(payload);
        const res = await axiosInstance.getIssues(payload.params);

        // console.log(res);
        if (res.data.code === 200) {
            return {
                issues: res.data.results.data,
                hasMore: res.data.results.has_more,
            };
        } else {
            throw new Error("Server Error!");
        }
    }
);

// Delete a Single Feedback
export const deleteFeedbackAsync = createAsyncThunk(
    "issues/deleteFeedbackAsync",
    async (id) => {
        console.log(id);
        await axiosInstance.deleteFeedback(id);

        return id;
    }
);

// Mark a feedback as Archived
export const markArchiveAsync = createAsyncThunk(
    "issues/markArchiveAsync",
    async (id) => {
        await axiosInstance.markArchive(id);

        return id;
    }
);

export const issueSlice = createSlice({
    name: "issues",
    initialState: {
        loading: false,
        hasErrors: false,
        issues: [],
        hasMore: false,
    },
    reducers: {
        emptyIssue: (state, action) => {
            state.issues = [];
        },
    },
    extraReducers: {
        [getIssuesAsync.pending]: (state, action) => {
            state.loading = true;
        },
        [getIssuesAsync.fulfilled]: (state, action) => {
            state.issues = [...state.issues, ...action.payload.issues];
            state.hasMore = action.payload.hasMore;
            state.loading = false;
            state.hasErrors = false;
        },
        [getIssuesAsync.rejected]: (state, action) => {
            state.loading = false;
            state.hasErrors = true;
        },
        [deleteFeedbackAsync.fulfilled]: (state, action) => {
            state.issues = state.issues.filter(
                (item) => item.id !== action.payload
            );
        },
        [markArchiveAsync.fulfilled]: (state, action) => {
            state.issues = state.issues.filter(
                (item) => item.id !== action.payload
            );
        },
    },
});

export const { emptyIssue } = issueSlice.actions;

export default issueSlice.reducer;
